import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Modal, Row, Table } from 'reactstrap';
import { createEntity, updateEntity, updateEntityProperty } from './accounts.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IAccounts } from 'app/shared/model/accounts.model';
import { Helmet } from "react-helmet";


interface InitialRequirementProps {
  onFormComplete: (completed: boolean) => void;
}

export interface InitialRequirementRef {
  syncInitialRequirementWithServer: () => Promise<any>;
  saveInitialRequirementWithServer : () => Promise<any>;
}

const InitialRequirement: React.ForwardRefRenderFunction<InitialRequirementRef, InitialRequirementProps> = ({ onFormComplete }: InitialRequirementProps, ref) => {
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const dispatch = useAppDispatch();
  const [checkBoxStates, setCheckBoxStates] = useState({
    over18: mainAccountsEntity.isAgeOverAgreed || false,
    beneficialOwner: mainAccountsEntity.beneficialOwnerAgreed || false,
    residentIreland: mainAccountsEntity.residentInIrelandAgreed || false,
    taxResidentIreland: mainAccountsEntity.taxResidentInIrelandAgreed || false,
    minimumInvestment: mainAccountsEntity.lumpSumAvailableToInvest || false,
    isTaxResidentInIreland: mainAccountsEntity.isTaxResidentInIreland || false,
  });
  const [accountHolderType, setAccountHolderType] = useState('Individual');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const customerDetails = useAppSelector(state => state.customer.entities);

  useEffect(() => {
    if (customerDetails) {
      setName(customerDetails?.firstName || '');
      setLastName(customerDetails?.lastName || '');
      setEmail(customerDetails?.email || '');
      setPhone(customerDetails?.phone || '');
    }
  }, [customerDetails]);

  useImperativeHandle(ref, () => ({
    syncInitialRequirementWithServer,
    saveInitialRequirementWithServer
  }));


  const syncInitialRequirementWithServer = async (): Promise<any> => {
    // if(Object.prototype.hasOwnProperty.call(Object(mainAccountsEntity), 'accountId') && mainAccountsEntity?.accountId !== '') {
    if (mainAccountsEntity?.accountId && mainAccountsEntity.accountId !== '') {

      return dispatch(updateEntity({ ...mainAccountsEntity, last_level: 2 }));
    } else {
      const body: IAccounts = {
        accountId: '',
        accountMasterType: 'Starlight Smart Reserve',
        accountHolderTitle: mainAccountsEntity.accountHolderTitle || '',
        accountHolderEmail: mainAccountsEntity.accountHolderEmail || '',
        accountHolderType: accountHolderType || '',
        accountHolderSurname: mainAccountsEntity.accountHolderSurname || '',
        accountHolderName: name || '',
        productType: mainAccountsEntity.accountHolderType ? [mainAccountsEntity.accountHolderType] : [],
        accountHolderMaritalStatus: "Married",
        accountHolderDependents: 0,
        accountHolderMobileNumber: phone || '',
        investmentAmount: mainAccountsEntity.investmentAmount || 0,
        isAgeOverAgreed: checkBoxStates.over18,
        beneficialOwnerAgreed: checkBoxStates.beneficialOwner,
        residentInIrelandAgreed: checkBoxStates.residentIreland,
        taxResidentInIrelandAgreed: checkBoxStates.taxResidentIreland,
        employmentIncomePercentage: null,
        propertyPercentage: null,
        giftPercentage: null,
        investmentPercentage: null,
        otherPercentage: null,
        totalNetWorth: null,
        liquidNetWorth: null,
        totalNetIncome: null,
        accountOwnerName: name || '',
        nameOfBank: 'Bank of Ireland',
        isPep: false,
        isFamilyPep: false,
        financialEmployee: false,
        securityQuestionAnswer: null,
        securityQuestionAnswer1: null,
        securityQuestionAnswer2: null,
        iban: '',
        isRegisteredInIreland: false,
        isTaxResidentInIreland: checkBoxStates.isTaxResidentInIreland,
        taxResidentInIreland: true,
        lumpSumAvailableToInvest: mainAccountsEntity.lumpSumAvailableToInvest,
        descriptionOfBusiness: '',
        last_level: 2,
        is_company_account: false,
        citizenship: 'Ireland',
        joint_details: {
          jointnametitle: '',
          jointfirstname: '',
          jointsurname: '',
          jointmaritalstatus: '',
          jointdependents: 1,
          jointmobilenumber: mainAccountsEntity.jointmobilenumber || '',
          jointaddressline1: '',
          jointaddressline2: '',
          jointtownorcity: '',
          jointcountry: '',
          jointpostcode: '',
          jointppsnumber: '',
          jointistaxresident: true,
          jointothertaxresidence: '',
          jointemploymentstatus: 'EMPLOYED',
          jointoccupation: '',
          jointemployername: '',
          jointemployerbusiness: '',
          jointemployeraddress: null,
          jointisapubliclylistedcompany: false,
          joint_username: mainAccountsEntity.joint_username || '',
          jointdob: mainAccountsEntity.jointdob || '',
          jointownership: 50,
          jointissuedcountry: 'Ireland',
          jointstateorprovince: "Galway",
          jointcountryofbirth: 'Ireland',
        },
      };

      try {
        const response = await dispatch(createEntity(body));
        return response;
      } catch (error) {
        console.error('Error creating entity:', error);
        throw error;
      }
    }
  };

  
  const saveInitialRequirementWithServer = (): Promise<any> => {
    const body: IAccounts = {
      accountId: '',
      accountMasterType: 'Starlight Smart Reserve',
      accountHolderTitle: mainAccountsEntity.accountHolderTitle || '',
      accountHolderEmail: mainAccountsEntity.accountHolderEmail || '',
      accountHolderType: accountHolderType || '',
      accountHolderSurname: mainAccountsEntity.accountHolderSurname || '',
      accountHolderName: name || '',
      productType: mainAccountsEntity.accountHolderType ? [mainAccountsEntity.accountHolderType] : [],
      accountHolderMaritalStatus: "Married",
      accountHolderDependents: 0,
      accountHolderMobileNumber: phone || '',
      investmentAmount: mainAccountsEntity.investmentAmount || 0,
      isAgeOverAgreed: checkBoxStates.over18,
      beneficialOwnerAgreed: checkBoxStates.beneficialOwner,
      residentInIrelandAgreed: checkBoxStates.residentIreland,
      taxResidentInIrelandAgreed: checkBoxStates.taxResidentIreland,
      employmentIncomePercentage: null,
      propertyPercentage: null,
      giftPercentage: null,
      investmentPercentage: null,
      otherPercentage: null,
      totalNetWorth: null,
      liquidNetWorth: null,
      totalNetIncome: null,
      accountOwnerName: name || '',
      nameOfBank: 'Bank of Ireland',
      isPep: false,
      isFamilyPep: false,
      financialEmployee: false,
      securityQuestionAnswer: null,
      securityQuestionAnswer1: null,
      securityQuestionAnswer2: null,
      iban: '',
      isRegisteredInIreland: false,
      isTaxResidentInIreland: checkBoxStates.isTaxResidentInIreland,
      taxResidentInIreland: true,
      lumpSumAvailableToInvest: mainAccountsEntity.lumpSumAvailableToInvest,
      descriptionOfBusiness: '',
      last_level: 2,
      is_company_account: false,
      citizenship: 'Ireland',
      joint_details: {
        jointnametitle: '',
        jointfirstname: '',
        jointsurname: '',
        jointmaritalstatus: '',
        jointdependents: 1,
        jointmobilenumber: mainAccountsEntity.jointmobilenumber || '',
        jointaddressline1: '',
        jointaddressline2: '',
        jointtownorcity: '',
        jointcountry: '',
        jointpostcode: '',
        jointppsnumber: '',
        jointistaxresident: true,
        jointothertaxresidence: '',
        jointemploymentstatus: 'EMPLOYED',
        jointoccupation: '',
        jointemployername: '',
        jointemployerbusiness: '',
        jointemployeraddress: null,
        jointisapubliclylistedcompany: false,
        joint_username: mainAccountsEntity.joint_username || '',
        jointdob: mainAccountsEntity.jointdob || '',
        jointownership: 50,
        jointissuedcountry: 'Ireland',
        jointstateorprovince: "Galway",
        jointcountryofbirth: 'Ireland',
      },
    };
      return dispatch(createEntity(body));
    }


  const handleCheckboxChange = (e: any) => {
    const { name: checkedName, checked } = e.target;
    setCheckBoxStates(prevState => ({
      ...prevState,
      [checkedName]: checked,
    }));
    if (checkedName === 'minimumInvestment') {

      dispatch(updateEntityProperty({ ['lumpSumAvailableToInvest']: checked }));
    } else {
      dispatch(updateEntityProperty({ [checkedName]: checked }));
    }
  };
  const handleAccountType = (e: any) => {
    setAccountHolderType(e.target.value);
    dispatch(updateEntityProperty({ accountHolderType: e.target.value }));
  };

  useEffect(() => {
    if (checkBoxStates.over18 &&
      checkBoxStates.beneficialOwner &&
      checkBoxStates.residentIreland &&
      checkBoxStates.taxResidentIreland &&
      checkBoxStates.minimumInvestment &&
      checkBoxStates.isTaxResidentInIreland && accountHolderType) {
      onFormComplete(true);
    } else {
      onFormComplete(false);
    }
  }, [checkBoxStates, accountHolderType])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
   
    const hasModalShown = sessionStorage.getItem('hasAccountModalShown');

    
    if (!hasModalShown && mainAccountsEntity.last_level === 1) {
      setIsModalOpen(true);
      sessionStorage.setItem('hasAccountModalShown', 'true');
    }

    
  }, [mainAccountsEntity]);
  

  return (
    <div>
      {mainAccountsEntity.last_level === 1 && (
      <Modal isOpen={isModalOpen} toggle={toggleModal} backdrop="static" centered>
          <div className="modal-header">
          </div>
          <div className="modal-body text-center" >
          <img 
            className="img-fluid logo-dashboard" 
            style={{ display: 'block', margin: '0 auto' }} 
            src="../../../../../content/images/new_logo_white_bg_color.svg" 
          />

            <div className='font-weight-bold custom-modal-style text-justify-custom pt-3'>
              <div className='pt-2 text-center'style={{ color: 'black', fontWeight: 'bold',fontSize:"16px" }}> Welcome to Starlight! </div>
            <div className='modal-text pt-3 text-center'>
            Thank you for choosing Starlight Smart Reserve  
            for the management of your cash reserves. 
            </div>
            <div className='modal-text pt-2 text-center'>
            It should take 5-8 minutes to complete the digital application. 
            </div>
            <div className='modal-text pt-2 text-center'>
               If you need to come back to us at any time, don’t worry, all information you submit can be saved by clicking ‘Save’ at the bottom of each screen, so you can always pick up where you left off. 
            </div>
            <div className='modal-text pt-2 text-center'>
              If you have any questions, please reach out to us at:
            </div>
            <div className='text-center'>
            <a href="mailto:Starlightnewaccounts@starlightwealth.ie"
              className="modal-text pt-2 text-center" style={{ color: '#607980' }}>
              Starlightnewaccounts@starlightwealth.ie
            </a>
            </div>
            <div className='modal-text pt-3 text-center'>
              The Starlight Wealth Team
            </div>
          </div>
          <div className="row pt-2 justify-content-center">
            <div className='col-6'>
              <Button className='button-account-custom-submit'
                onClick={() => { toggleModal(); }}>
                Let’s Get Started
              </Button>
            </div>
          </div>
           </div>
          
</Modal>
      )}
      
      <Row className="form-firstrow-account-custom pt-3">
        <Col md="12" className="form-heading-custom">
          Initial Requirements Check
        </Col>
        <div className="form-sub-content-custom">You must be able to meet all of these requirements to open an account</div>
      </Row>
      <Row className="pb-3">
        <Col md="12">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span> Account Type
              </Label>
              <Input
                type="select"
                name="accountHolderType"
                id="accountHolderType"
                className="custom-from-input"
                value={accountHolderType}
                defaultValue={mainAccountsEntity.accountHolderType}
                onChange={handleAccountType}
              >
                <option value="Individual">Individual</option>
                <option value="Joint" selected>Joint</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup >
              <Label className="label-custom-form-check-box" check>
                <Input
                  type="checkbox"
                  name="over18"
                  checked={checkBoxStates.over18}
                  className="input-account-custom-bgnone me-1"
                  onChange={handleCheckboxChange}
                />{' '}
                I am over 18
              </Label>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup >
              <Label className="label-custom-form-check-box" check>
                <Input
                  type="checkbox"
                  name="beneficialOwner"
                  checked={checkBoxStates.beneficialOwner}
                  className="input-account-custom-bgnone me-1"
                  onChange={handleCheckboxChange}
                />{' '}
                I will be the beneficial owner of the account
              </Label>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup >
              <Label className="label-custom-form-check-box" check>
                <Input
                  type="checkbox"
                  name="residentIreland"
                  checked={checkBoxStates.residentIreland}
                  className="input-account-custom-bgnone me-1"
                  onChange={handleCheckboxChange}
                />{' '}
                I am resident in Ireland
              </Label>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup >
              <Label className="label-custom-form-check-box" check>
                <Input
                  type="checkbox"
                  name="taxResidentIreland"
                  checked={checkBoxStates.taxResidentIreland}
                  className="input-account-custom-bgnone me-1"
                  onChange={handleCheckboxChange}
                />{' '}
                I am tax resident in ireland and have a PPS number
              </Label>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup >
              <Label className="label-custom-form-check-box" check>
                <Input
                  type="checkbox"
                  name="minimumInvestment"
                  checked={checkBoxStates.minimumInvestment}
                  className="input-account-custom-bgnone me-1"
                  onChange={handleCheckboxChange}
                />{' '}
                I have minimum lump sum of 50,000 available to invest and I have a minimum of 3 months living expenses covered from other funds
              </Label>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup >
              <Label className="label-custom-form-check-box" check>
                <Input
                  type="checkbox"
                  name="isTaxResidentInIreland"
                  checked={checkBoxStates.isTaxResidentInIreland}
                  className="input-account-custom-bgnone me-1"
                  onChange={handleCheckboxChange}
                />{' '}
                My investment objective is to preserve capital and generate a return that reflects the European Central Bank (ECB) deposit
                rate
              </Label>
            </FormGroup>
          </Col>
          <Helmet> 
          <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/145768795.js" ></script>
          </Helmet>
      </Row>

    </div>
  );
};
export default forwardRef(InitialRequirement);
