import React, { memo, useEffect, useRef, useState } from 'react';
import { Col, Input, Label, Row, FormGroup, Form, Button } from 'reactstrap';
import './account.scss';
import { useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getAgreements, getSignature } from './accounts.reducer';
import { getPendingTask } from 'app/modules/profile/profile.reducer';
import { log } from 'react-jhipster';
import { getAdminAccounts } from '../admin-profile/admin.reduce';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment-timezone';
import { Helmet } from "react-helmet";

const InteractiveBrokers = ({ interactive, accountHolderType, ibkrPrimary_sign, setibkrPrimarySign = (data)=>{}, ibkrSecondarySign,
  setibkrSecondarySign,
  // handleCheckboxibustomerAgreement,
  // handleCheckboxibclientFinancialAdvisor,
  // handleCheckboxelt,
  // handleCheckboxibsdNotification,
  // handleCheckboxLLA,
  handleCheckboxChange,
  accountIdNum }) => {
  const location = useLocation();
  const signatureData = useAppSelector(state => state.accounts.signatureData);
  const dispatch = useAppDispatch();
  const { level, _signatureDataPending } = location.state || {};

  const [customerId, setCustomerId] = useState()
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);

  const { accountId } = useParams();

  const [applicationData, setApplicationData] = useState<any>({});
  const [newData, setNewData] = useState<any>({});
  const [signatureTimestamp, setSignatureTimestamp] = useState<string>(''); 
  const [jointsignatureTimestamp, setJointSignatureTimestamp] = useState<string>(''); 



  useEffect(() => {
    if (accountId) {
      dispatch(getAdminAccounts({ accountId }))
        .then((response: any) => {
          if (response?.payload?.data) {
            setApplicationData(response?.payload?.data?.account_signature_response);
            setNewData(response?.payload?.data)

          }
        })
        .catch((error) => {
        });
    }
  }, [accountId]);  

  const handleLinkClick = async (e) => {
    e.preventDefault();
    try {
      await dispatch(getAgreements()).unwrap();
    } catch (error) {
      console.error('Error fetching agreements:', error);
    }
    open('/terms-and-disclosures')
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {

      const tokenParts = accessToken.split('.');

      if (tokenParts?.length === 3) {
        try {
          const payload = JSON.parse(atob(tokenParts[1]));

          if (payload?.sub) {
            const { sub } = payload;
            setCustomerId(sub)
          }
        } catch (error) {
          console.error("Failed to parse JWT payload", error);
        }
      }
    }
  }, []);

  const [secondaryId, setSecondaryId] = useState()
  useEffect(() => {
    dispatch(getPendingTask({}))
      .then((response: any) => {
        const signaturePending = response?.payload?.data?.signature_pending;

        if (signaturePending) {
          signaturePending.forEach((item) => {
            setSecondaryId(item.secondary_customer_id);
          });
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [dispatch]);

  useEffect(() => {
    if (mainAccountsEntity?.accountId) {
      dispatch(getSignature(mainAccountsEntity?.accountId));
    }
  }, [mainAccountsEntity?.accountId, dispatch]);
  useEffect(() => {
    if (signatureData?.ibkr_signature?.primary_sign != null) {
      setibkrPrimarySign(signatureData?.ibkr_signature?.primary_sign);

    }
  }, [signatureData]);



  const pageStyle = `
 
  @media print {

  @page {
  size: auto;
  margin-top: 20mm;
  margin-bottom: 20mm;
}
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
    body {
      font-size: 13px;
    }

    .logo-show{
display:block !important;
}
 
    .no-print {
      display: none;
    }
 
    .new-form-bold-text,
    .new-form-italic-text,
    .new-form-underline-text {
      font-size: 13px;
      font-weight: bold;
      color: black;
    }
 
    .new-form-heading-text {
      font-weight: bold;
      font-size: 25px;
      color: black;
    }
 
    .new-form-light-text {
      font-weight: 300;
      font-size: 13px;
      color: black;
    }
 
    .new-form-very-light-text {
      font-weight: 300;
      font-size: 12px;
      color: black;
    }
 
    .new-form-italic-text {
      font-style: italic;
    }
 
    .bordered-table {
      width: 100%;
      border-collapse: collapse;
    }
    .bordered-table th,
    .bordered-table td {
      border: 1px solid black;
      padding: 8px;
      font-weight: 300;
      font-size: 13px;
      color: black;
    }
    .bordered-table th {
      background-color: #f2f2f2;
      font-weight: 300;
      font-size: 13px;
      color: black;
    }
    .new-form-table-row-one {
      background: #607980;
      color: black;
      font-weight: 300;]
      font-size: 13px;
    }
    .new-form-table-row-two {
      background-color: #d5d8da;
      color: black;
      font-weight: 300;
      font-size: 13px;
    }
  }
 
`;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Statement of Suitability',
    onBeforePrint: () => console.log('before printing...'),
    onAfterPrint: () => console.log('after printing...'),
    pageStyle,
    removeAfterPrint: true,
  });
  const componentRef = useRef();

    const isPrintButtonEnabled = () => {
    if (accountHolderType === 'Individual' || applicationData?.account_type === 'Individual') {
      return !!ibkrPrimary_sign; 
    } else if (accountHolderType === 'Joint') {
      const isPrimaryEditable = customerId === mainAccountsEntity.customerId; 
      const isSecondaryEditable = customerId === secondaryId;

      const isPrimaryValid = isPrimaryEditable ? !!ibkrPrimary_sign : true; 
      const isSecondaryValid = isSecondaryEditable ? !!ibkrSecondarySign : true; 

      return isPrimaryValid && isSecondaryValid; 
    }
    return false; 
  };

  const handleSignatureChange = (setter: Function, value: string) => {
    setter(value);
    const userTimezone = moment.tz.guess(); 
    const currentDateTimeWithZone = moment().utc().format('DD-MM-YYYY HH:mm:ss') + ' GMT'; 
    const formattedDateTimeWithCity = `${currentDateTimeWithZone} (${userTimezone.replace('_', ' ')})`;
    setSignatureTimestamp(formattedDateTimeWithCity);
    localStorage.setItem('ibkrsignatureTimestamp', formattedDateTimeWithCity); 
  };
  const formattedprimaryApiTimestamp = applicationData?.ibkr_signature?.primary_signed_timestamp
  ? moment.utc(applicationData?.ibkr_signature?.primary_signed_timestamp, "DD-MM-YYYYTHH:mm:ss.SSSSSSZ")
      .tz(moment.tz.guess())
      .format("DD-MM-YYYY HH:mm:ss [GMT]") + ` (${moment.tz.guess()})`
  : null;

  const handleJointSignatureChange = (setter: Function, value: string) => {
    setter(value);
    const userTimezone = moment.tz.guess(); 
    const currentDateTimeWithZone = moment().utc().format('DD-MM-YYYY HH:mm:ss') + ' GMT'; 
    const formattedDateTimeWithCity = `${currentDateTimeWithZone} (${userTimezone.replace('_', ' ')})`;
    setJointSignatureTimestamp(formattedDateTimeWithCity);
    localStorage.setItem('ibkrjointsignatureTimestamp', formattedDateTimeWithCity); 
  };
  const formattedjointApiTimestamp = applicationData?.ibkr_signature?.joint_signed_timestamp
  ? moment.utc(applicationData?.ibkr_signature?.joint_signed_timestamp, "DD-MM-YYYYTHH:mm:ss.SSSSSSZ")
      .tz(moment.tz.guess())
      .format("DD-MM-YYYY HH:mm:ss [GMT]") + ` (${moment.tz.guess()})`
  : null;

  useEffect(() => {
    const storedSignatureTimestamp = localStorage.getItem('ibkrsignatureTimestamp');
    const storedJointSignatureTimestamp = localStorage.getItem('ibkrjointjointsignatureTimestamp');
    
    if (storedSignatureTimestamp) {
      setSignatureTimestamp(storedSignatureTimestamp);
    }
  
    if (storedJointSignatureTimestamp) {
      setJointSignatureTimestamp(storedJointSignatureTimestamp);
    }
  }, []);

  return (
    <div ref={componentRef} id={'datauristring'}>
      <Row className='bg-white pt-2 text-justify-custom'>
        <Col md="12" >
          <div className=" justify-content-center">
            <Form className=" bg-white px-3 py-2">
            <Col md="12" className='logo-show text-end'>
                            <img className="img-fluid logo-form" src="../../../../../content/images/new_logo_white_bg_color.svg" />
                                {/* <label style={{ fontSize: '30px', fontWeight: '300', fontFamily: 'Raleway', color: 'black',padding:'10px',border:'1px solid black'}}>Starlight Wealth</label> */}
              </Col>
              <Row>
                <Col md="12" className="pt-3 text-start new-form-heading-text">
                Starlight Smart Account & Interactive Brokers Ireland <br /><br />
                </Col>
                <Col md="12 new-form-light-text">
                  Starlight Smart Reserve and Starlight Smart Invest are provided via the <span className="new-form-bold-text">Starlight Smart Account</span>, a self-directed advisory investment account opened in your name with our investment broker, Interactive Brokers Ireland Limited ("Interactive Brokers Ireland"). <br /><br />
                </Col>
                <Col md="12 new-form-light-text">
                Interactive Brokers Ireland is a MIFID authorised investment firm regulated by the Central Bank of Ireland and is a wholly owned group subsidiary of Interactive Brokers Group LLC, an A- publicly rated and listed global investment firm with a market capitalisation exceeding US$32bn, US$14bn in equity capital and over 2.5 million customers. <br /><br />
                </Col>
                <Col md="12 new-form-light-text">
                  Your cash and investments in your account are 'client assets' and are fully segregated from the assets of Interactive Brokers Ireland in accordance with Central Bank of Ireland regulatory rules. In the unlikely event of Interactive Brokers Ireland failure or insolvency, your assets are not available or accessible to Interactive Brokers Ireland's creditors and would be returned to you. <br /><br />
                </Col>
                <Col md="12 new-form-light-text">
                  Starlight Wealth does not accept, hold, or have any access to your cash or investments and so in the unlikely event of our failure, your cash and investments are not available or accessible to our creditors. <br /><br />
                </Col>
                
                <Col md="12 new-form-light-text"><br />
                  To open a <span className="new-form-bold-text">Starlight Smart Account</span>, you must agree to Interactive Brokers Ireland's standard terms and disclosures.
                </Col>
                <Col md="12" className=" new-form-bold-text pt-3">
                  Interactive Brokers Ireland Standard Terms and Disclosures
                </Col> <br />
                <Col md="12">
                  <FormGroup check>
                    <Label className="label-account-class" check style={{fontSize:"14px"}}>
                      <Input
                        type="checkbox"
                        name="ib_customer_agreement"
                        checked={interactive?.ib_customer_agreement || newData?.ib_customer_agreement}
                        className="input-front-account-custom-bgnone"
                        onChange={handleCheckboxChange}
                      />{' '}
                      <a href="https://starwealth-dev.azureedge.net/development-storage/Interactive%20Brokers%20Ireland%20Ltd%20Customer%20AgreementForm4750.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#597177' }}>
                        Interactive Brokers Ireland Ltd Customer Agreement
                      </a>
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup check >
                    <Label className="label-account-class" check style={{fontSize:"14px"}}>
                      <Input
                        type="checkbox"
                        name="ib_client_financial_advisor"
                        checked={interactive?.ib_client_financial_advisor || newData?.ib_client_financial_advisor}
                        className="input-front-account-custom-bgnone"
                        onChange={handleCheckboxChange}
                      />{' '}
                      <a href="https://starwealth-dev.azureedge.net/development-storage/Agreement%20Limiting%20Interactive%20Brokers%20LiabilityForm6108.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#597177' }}>
                      Interactive Brokers Ireland Ltd Client Financial Advisor Agreement
                      </a>
                    </Label>
                    {/* //test docType */}

                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup check>
                    <Label className="label-account-class" check style={{fontSize:"14px"}}>
                      <Input
                        type="checkbox"
                        name="elt"
                        checked={interactive?.elt || newData?.elt}
                        className="input-front-account-custom-bgnone"
                        onChange={handleCheckboxChange}
                      />{' '}
                      <a href="https://starwealth-dev.azureedge.net/development-storage/Form4772Advisory.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#597177' }}>
                        Essential Legal Terms for your Account
                      </a>
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup check>
                    <Label className="label-account-class" check style={{fontSize:"14px"}}>
                      <Input
                        type="checkbox"
                        name="lla"
                        checked={interactive?.lla || newData?.lla}
                        className="input-front-account-custom-bgnone"
                        onChange={handleCheckboxChange}
                      />{' '}
                      <a href="https://starwealth-dev.azureedge.net/development-storage/Legal%20AcknowledgementForm2109.pdf" target="_blank" rel="noopener noreferrer" style={{ color: '#597177' }}>
                        Limited Liability Agreement
                      </a>
                    </Label>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup check>
                    <Label className="label-account-class" check style={{fontSize:"14px"}}>
                      <Input
                        type="checkbox"
                        name="ibsd_notification"
                        checked={interactive?.ibsd_notification || newData?.ibsd_notification}
                        className="input-front-account-custom-bgnone"
                        onChange={handleCheckboxChange}
                      />{' '}
                      <a href="https://starwealth-dev.azureedge.net/development-storage/Interactive%20Brokers%20Ireland%20Ltd%20Retail%20Client%20NotificationForm4769.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#597177' }}
                        onClick={handleLinkClick}
                      >
                        Interactive Broker standard disclosures and notifications (click to view all)
                      </a>
                    </Label>
                  </FormGroup>
                </Col>
                <div>
                  <br />
                  <div>
                    <label className='signature-container-inside-box-2'>Please tick the boxes above and type your full name in the space below to confirm your agreement to the standard terms and disclosures.</label>
                  </div>
                  <div className="col-md-6 signature-container-inside-box-2"style={{fontSize:"14px"}}>
                    <div>
                      {(accountHolderType === 'Individual' || applicationData?.account_type === 'Individual') ? (
                        <>

                          <div>
                            <Input
                              type="text"
                              className="signature-container-inside-box-2-input"
                              value={ibkrPrimary_sign || applicationData?.ibkr_signature?.primary_sign}
                              onChange={(e) => handleSignatureChange(setibkrPrimarySign,e.target.value)}
                            />
                          </div>
                          <p className="timestamp-text">Date and Time: {signatureTimestamp || formattedprimaryApiTimestamp}</p> {/* Display timestamp */}
                        </>
                      ) : (
                        <>
                          {/* <div>
                            <label>Please tick the boxes above and type your fullName in the space below to confirm that standard terms and disclosures.</label>
                          </div> */}
                          <div>
                            {customerId === mainAccountsEntity?.customerId ? (
                              <Input
                                type="text"
                                className="signature-container-inside-box-2-input"
                                value={_signatureDataPending?.account_type === 'Joint' ? _signatureDataPending?.ibkr_signature
                                  ?.primary_sign : ibkrPrimary_sign || ''}
                                onChange={(e) => handleSignatureChange(setibkrPrimarySign,e.target.value)}
                                id="signPrimary"

                              />
                              
                            ) : (
                              <Input
                                type="text"
                                className="signature-container-inside-box-2-input"
                                value={_signatureDataPending?.account_type === 'Joint' ? _signatureDataPending?.ibkr_signature
                                  ?.primary_sign : ibkrPrimary_sign || applicationData?.ibkr_signature?.primary_sign}
                                id="signPrimary"
                                readOnly

                              />
                            )}
                           <p className="timestamp-text">Date and Time: {signatureTimestamp || formattedprimaryApiTimestamp}</p> {/* Display timestamp */}

                          </div>
                          <div>
                            <label>Signature of Secondary owner</label>
                          </div>
                          <div>
                            {customerId !== (secondaryId || mainAccountsEntity?.secondary_customer_id) ? (
                              <Input
                                type="text"
                                id="signSecondary"
                                className="signature-container-inside-box-2-input"
                                value={ibkrSecondarySign || applicationData?.ibkr_signature?.joint_sign}
                                readOnly

                              />
                            ) : (
                              <Input
                                type="text"
                                id="signSecondary"
                                className="signature-container-inside-box-2-input"
                                value={ibkrSecondarySign || ''}

                                onChange={(e) => handleJointSignatureChange(setibkrSecondarySign,e.target.value)}

                              />
                            )}
                           <p className="timestamp-text">Date and Time: {jointsignatureTimestamp || formattedjointApiTimestamp}</p> {/* Display timestamp */}

                          </div>

                        </>
                      )
                      }
                    </div>
                  </div>
                </div>
              </Row>
              <Row>
                <FormGroup>
                  <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                </FormGroup>
              </Row>
              <Col md="12" className="text-end no-print">
                <Button className="print_btn_w8"   
                 disabled={   !(interactive?.elt &&
                              interactive?.ib_client_financial_advisor &&
                              interactive?.ib_customer_agreement &&
                              interactive?.ibsd_notification &&
                              interactive?.lla) ||
                            (mainAccountsEntity.customerId === customerId ? !(ibkrPrimary_sign) : !(ibkrSecondarySign))
                          }  
                onClick={() => handlePrint({ content: () => document.body })}>Print</Button>
              </Col>
            </Form>
          </div>
        </Col>
        <Helmet> 
        <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/145768795.js" ></script>
        </Helmet>
      </Row>
    </div>
  );

}
export default memo(InteractiveBrokers);
