import Lottie from 'lottie-react';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import animationData from '../../../content/lottie/upload_loader.json';
import { FileEarmarkMedicalFill, Trash } from 'react-bootstrap-icons';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { ValidatedField, log } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { uploadDocument, getCountriesList, updateEntity, updateEntityProperty, getCountryList, getState } from '../accounts/accounts.reducer';
import { toast } from 'react-toastify';
import DropZone from 'app/modules/DropZone';
import CommonToast from 'app/modules/components/CommonToast';
import { getPendingTask } from 'app/modules/profile/profile.reducer';
import { getCustomerDetails } from '../customer/customer.reducer';
import ValidatedDatePickerField from 'app/modules/components/ValidatedDatePickerField';
import { useForm } from 'react-hook-form';
import { Helmet } from "react-helmet";

interface primaryDetailsProp {
  onFormComplete: (completed: boolean) => void;
}

export interface primaryDetailsRef {
  syncPrimaryDetailsWithServer: () => Promise<any>;
  savePrimaryDetailsWithServer: () => Promise<any>;
}

const PrimaryApplication: React.ForwardRefRenderFunction<primaryDetailsRef, primaryDetailsProp> = ({ onFormComplete }: primaryDetailsProp, ref) => {
  const MANDATORY: string = 'This field is mandatory';
  const dispatch = useAppDispatch();
  const customerDetails = useAppSelector(state => state.customer.entities);
  const [isUploadingOne, setIsUploadingOne] = useState(false);
  const [isUploadingTwo, setIsUploadingTwo] = useState(false);
  const [investError, setInvestError] = useState('');
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const accountsEntity = sessionStorage?.getItem('accountid');
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const [countryList, setCountryList] = useState<string[]>(mainAccountsEntity?.countryList || []);

  const [nameTitle, setNameTitle] = useState<string>(mainAccountsEntity?.accountHolderTitle || 'Mr');
  const [accountHolderName, setAccountHolderName] = useState<string>(mainAccountsEntity?.accountHolderName || '');
  const [lastName, setLastName] = useState<string>(mainAccountsEntity?.accountHolderSurname || '');
  const [middleName, setMiddleName] = useState<string>(mainAccountsEntity?.middle_name || '');
  const [email, setEmail] = useState<string>(mainAccountsEntity?.accountHolderEmail || '');
  const [phone, setPhone] = useState<string>(mainAccountsEntity?.accountHolderMobileNumber || '');
  const [maritalstatus, setMaritalstatus] = useState<string>(mainAccountsEntity?.accountHolderMaritalStatus || '');
  const [dependents, setdependents] = useState<string>(mainAccountsEntity?.accountHolderDependents || '0');
  const [AccountType, setAccountType] = useState<string>(mainAccountsEntity?.accountHolderType || '');
  const [addressLine1, setAddressLine1] = useState<string>(mainAccountsEntity?.addressLine1 || '');
  const [addressLine2, setAddressLine2] = useState<string>(mainAccountsEntity?.addressLine2 || '');
  const [townOrCity, setTownorcity] = useState<string>(mainAccountsEntity?.townOrCity || '');
  const [state_or_province, setState_or_province] = useState<string>(mainAccountsEntity?.state_or_province ||'');
  const [postcode, setPostcode] = useState<string>(mainAccountsEntity?.postcode || '');
  const [ppsNumber, setPpsnumber] = useState<string>(mainAccountsEntity?.ppsNumber || '');
  const [passport_number, setpassport_number] = useState<string>(mainAccountsEntity?.passport_number || '');
  const [country, setCountry] = useState<any>("Ireland");

  const [issued_country, setIssued_country] = useState<any>(mainAccountsEntity?.countryList?.find((data) => data.name === 'Ireland') || {});
  const [passport_expiry_date, setPassport_expiry_date] = useState<string>(mainAccountsEntity?.passport_expiry_date || '');
  const [countryOfBirth, setCountryOfBirth] = useState<any>(mainAccountsEntity?.countryList?.find((data) => data.name === 'Ireland') || {});
  const [citizenship, setCountryOfCitizenship] = useState<any>(mainAccountsEntity?.countryList?.find((data) => data.name === 'Ireland') || {});

  const [istaxresident, setIstaxresident] = useState<boolean>(
    mainAccountsEntity?.taxResidentInIreland ?? true
  );

  const [is_tax_resident_in_another_country, setIstaxresidentinAnotherCountry] = useState<boolean>(
    mainAccountsEntity?.is_tax_resident_in_another_country ?? false
  );
  type PassportExpiryErrorType = {
    passport_expiry_date?: string;
  };
  
  const [othertaxresidence, setOthertaxresidence] = useState<string>(mainAccountsEntity?.othertaxresidence || '');
  const [employment_status, setEmployment_status] = useState<string>(mainAccountsEntity?.employment_status || 'EMPLOYED');
  const [occupation, setOccupation] = useState<string>(mainAccountsEntity?.occupation || '');
  const [employerName, setEmployerName] = useState<string>(mainAccountsEntity?.employerName || '');
  const [investmentAmount, setInvestmentAmount] = useState<string>(mainAccountsEntity?.investmentAmount || '');
  const [employerBusiness, setEmployerBusiness] = useState<string>(mainAccountsEntity?.employerBusiness || '');
  const [isapubliclylistedcompany, setIsapubliclylistedcompany] = useState<string>(false || mainAccountsEntity?.isapubliclylistedcompany);
  const [employerCompany, setEmployerCompany] = useState<string>(mainAccountsEntity?.employerCompany || '');
  const [uploadedFile, setUploadedFile] = useState<string>(mainAccountsEntity?.ppsNumberDoc || '');
// const [uploadedFile, setUploadedFile] = useState<{ fileData: string; fileName: string }>({
//   fileData: '',
//   fileName: mainAccountsEntity?.ppsNumberDoc || '',
// });

  const [uploadedPassport, setUploadedPassport] = useState<string>(mainAccountsEntity?.passportDoc || '');
  // const [uploadedPassport, setUploadedPassport] = useState<{ fileData: string; fileName: string }>({
  //   fileData: '',
  // fileName:mainAccountsEntity?.passportDoc || ''});
  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({});
  const [passportexpiryError, setPassportExpiryError] = useState<PassportExpiryErrorType>({});
  const [ppsError, setPpsError] = useState<string | null>(null);
  const [pendingTaskAccountId, setPendingTaskAccountId] = useState([])
  const [stateList, setStateList] = useState<string[]>([]);


  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    getFieldState,
    formState: { isValid,touchedFields },
  } = useForm({ mode: 'onChange' });

  const findCountryNameByKey = (key: string | null | undefined): string => {
    const countryName: any = countryList.find((obj: any) => obj.name === key);
    return countryName ? countryName.name : '';
  };

  const findStateNameByKey = (key: string | null | undefined): string => {
    const stateOrProvince: any = stateList.find((obj: any) => obj.name === key);
    return stateOrProvince ? stateOrProvince.name : '';
  };

  const initialCountryName = mainAccountsEntity?.country
    ? findCountryNameByKey(mainAccountsEntity.country)
    : '';
  const [employerAddress, setEmployerAddress] = useState({
    street: mainAccountsEntity?.employerAddress?.street,
    city: mainAccountsEntity?.employerAddress?.city,
    state: mainAccountsEntity?.employerAddress?.state  ,
    country: mainAccountsEntity?.employerAddress?.country || 'Ireland',
    postal_code: mainAccountsEntity?.employerAddress?.postal_code

  });
  // const [country, setCountry] = useState<string>(initialCountryName);

  interface IValidationErrors {
    [key: string]: string;
  }

  useImperativeHandle(ref, () => ({
    syncPrimaryDetailsWithServer,
    savePrimaryDetailsWithServer
  }));

  useEffect(() => {
    dispatch(getPendingTask({}))
      .then((response: any) => {
        setPendingTaskAccountId(response?.payload?.data?.pending_accounts)
      })
  }, [])
  useEffect(() => {
    dispatch(getState()).then((res) => {
      if (res.payload) {
        const stateData = res.payload.map((item: any) => item);
        setStateList(stateData);
      }
    });

  }, [dispatch]);

  const accessToken = localStorage.getItem('jhi-authenticationToken');
  useEffect(() => {

    if (accessToken) {
      dispatch(getCountryList()).then((res) => {
        dispatch(getCustomerDetails());
        if (res.payload) {
          const countryData = res.payload.map((item: any) => item);
          setCountryList(countryData);
        }
      });
    }

  }, [dispatch]);

  // (employerAddress.state === '' || (employerAddress.state== null)
  //       ? mainAccountsEntity?.(employerAddress.state:employerAddress.state, 
  useEffect(() => {
    if (customerDetails) {
      setAccountHolderName(customerDetails?.firstName || '');
      setLastName(customerDetails?.lastName || mainAccountsEntity?.accountHolderSurname || '');
      setEmail(customerDetails?.email || '');
      setPhone(customerDetails?.phone || '');

      const updatedProperties = {
      accountHolderTitle: customerDetails?.accountHolderTitle ? customerDetails.accountHolderTitle : mainAccountsEntity?.accountHolderTitle || 'Mr',
      accountHolderSurname: customerDetails?.lastName,
      accountHolderEmail: customerDetails?.email,
      state_or_province:(state_or_province === '' || state_or_province== null)? mainAccountsEntity?.state_or_province: state_or_province,  
      country:(country === '' || country== null)? (mainAccountsEntity?.country|| 'Ireland'): country,
      issued_country: mainAccountsEntity?.issued_country ? mainAccountsEntity.issued_country : 'Ireland',
      accountHolderDependents: customerDetails?.accountHolderDependents ? customerDetails.accountHolderDependents : mainAccountsEntity?.accountHolderDependents || '0',
      employment_status: customerDetails?.employmentStatus ||mainAccountsEntity.employment_status || 'EMPLOYED',
      employerAddress: {
          ...mainAccountsEntity?.employerAddress,
          country: (employerAddress?.country === '' || employerAddress?.country== null)
          ? (mainAccountsEntity?.employerAddress?.country||'Ireland'):employerAddress?.country,
          state: (employerAddress?.state === '' || employerAddress?.state== null)
          ? (mainAccountsEntity?.employerAddress?.state||''):employerAddress?.state, 
        },
        birth_info: {
          countryOfBirth: mainAccountsEntity?.birth_info?.countryOfBirth || 'Ireland',
        },
        taxResidentInIreland:     mainAccountsEntity?.taxResidentInIreland ?? true,
  
        passport_expiry_date: (passport_expiry_date === '' || passport_expiry_date== null)
        ? mainAccountsEntity?.passport_expiry_date: passport_expiry_date,
      };

      dispatch(updateEntityProperty(updatedProperties));
    }
  }, [customerDetails]);

  const syncPrimaryDetailsWithServer = (): Promise<any> => {

    const _errors: IValidationErrors = {};
    const minAllowedDate = getFifteenDaysFromToday();
    const numericInvestmentAmount = parseFloat(
      typeof investmentAmount === 'string' ? investmentAmount?.replace(/[^\d.]/g, '') : investmentAmount || '0'
    );
  
    if (isNaN(numericInvestmentAmount) || numericInvestmentAmount < 50000) {
      _errors.investmentAmount = numericInvestmentAmount
        ? ''
        : 'This field is mandatory';
    }
    if (!addressLine1) {
      _errors.addressLine1 = 'This field is mandatory';
    }
    if (!townOrCity) {
      _errors.townOrCity = 'This field is mandatory';
    }
    if (!postcode) {
      _errors.postcode = 'This field is mandatory';
    }
    // if (!ppsNumber) {
    //   _errors.ppsNumber = 'This field is mandatory';
    // }
    // if (!uploadedFile) {
    //   _errors.uploadedFile = 'This field is mandatory';
    // }
    // if (!passport_number) {
    //   _errors.passport_number = 'This field is mandatory';
    // }
    // if (!uploadedPassport) {
    //   _errors.uploadedPassport = 'This field is mandatory';
    // }
    // if (!citizenship) {
    //   _errors.citizenship = 'This field is mandatory';
    // }
    // if (!state_or_province){
    //   _errors.state_or_province = 'This field is mandatory';
    // }
    // if (!passport_expiry_date) {
    //   _errors.passport_expiry_date = 'This field is mandatory';
    // }
    // if (!passport_expiry_date) {
    //   _errors.passport_expiry_date = 'This field is mandatory';
    // } else if (new Date(passport_expiry_date) < new Date(minAllowedDate)) {
    //   _errors.passport_expiry_date = `Passport expiry date must be at least 15 days from today.`;
    // }
    // if (istaxresident === false && !othertaxresidence) {
    //   _errors.othertaxresidence = 'This field is mandatory';
    // }
    // if (employment_status === "EMPLOYED") {
    //   if (!occupation) {
    //     _errors.occupation = 'This field is mandatory';
    //   }
    //   if (!employerName) {
    //     _errors.employerName = 'This field is mandatory';
    //   }
    //   if (!employerBusiness) {
    //     _errors.employerBusiness = 'This field is mandatory';
    //   }
    //   if (!employerAddress?.city) {
    //     _errors.city = 'This field is mandatory';
    //   }
    //   if (!employerAddress?.state){
    //     _errors.state ='This field is mandatory';
    //   }
    //   if (!employerAddress?.postal_code) {
    //     _errors.postal_code = 'This field is mandatory';
    //   }
    //   if (!employerAddress?.street) {
    //     _errors.street = 'This field is mandatory';
    //   }
    // }
    if (Object.keys(_errors).length > 0) {
      setValidationErrors(_errors);
      setToastMessage((prev) => 'Please fill all the mandatory fields');
      setToastType('error');
      setToastVisible(true);
      return null;
    }
    setValidationErrors({});
    return dispatch(updateEntity({ ...mainAccountsEntity, last_level: 3 }));
  };

  const savePrimaryDetailsWithServer = (): Promise<any> => {
    if (mainAccountsEntity?.accountId && mainAccountsEntity.accountId !== '') {

      return dispatch(updateEntity({ ...mainAccountsEntity }));
    }
    }

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    const { name, value, id } = e.target;
    const lcl_errors: { [key: string]: string } = {};

    if (name === 'accountHolderTitle') {
      setNameTitle(value);
    } else if (name === 'middle_name') {
      setMiddleName(value);
    } else if (name === 'accountHolderName') {
      setAccountHolderName(value);
    } else if (name === 'accountHolderSurName') {
      setLastName(value);
    } else if (name === 'accountHolderEmail') {
      setEmail(value);
    } else if (name === 'accountHolderMaritalStatus') {
      setMaritalstatus(value);
    } else if (name === 'accountHolderDependents') {
      setdependents(value);
    } else if (name === 'addressLine1') {
      setAddressLine1(value);
    } else if (name === 'addressLine2') {
      setAddressLine2(value);
    } else if (name === 'townOrCity') {
      setTownorcity(value);
    } else if (name === 'state_or_province') {
      setState_or_province(value);
    } else if (name === 'country') {
      setCountry(value);
    } else if (name === 'issued_country') {
      setIssued_country(value);
    } else if (name === 'postcode') {
      setPostcode(value.toUpperCase());
    } else if (name === 'passport_number') {
      setpassport_number(value);
    } else if (name === 'countryOfBirth') {
      setCountryOfBirth(value);
    } else if (name === 'countryOfCitizenship') {
      setCountryOfCitizenship(value);
    } else if (name === 'othertaxresidence') {
      setOthertaxresidence(value);
    }
    else if (name === 'occupation') {
      setOccupation(value);
    } else if (name === 'employment_status') {
      setEmployment_status(value);
    } else if (name === 'employerName') {
      setEmployerName(value);
    } else if (name === 'employerBusiness') {
      setEmployerBusiness(value);
    }
    else if (name === 'isapubliclylistedcompany') {
      setIsapubliclylistedcompany(value);
    }
    else if (name === 'employerCompany') {
      setEmployerCompany(value);
    }
    if (value) {
      clearValidationError(name);
    }
    dispatch(updateEntityProperty({
      [name]: value
    }));

  };

  const handleChangePhone = (e: any) => {
    const lcl_phone = e.target.value;
    dispatch(updateEntityProperty({ accountHolderMobileNumber: lcl_phone }));
  }

  const clearValidationError = (field: string) => {
    setValidationErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
    setPassportExpiryError(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleCountryIssued = (e: any) => {
    const { name, value } = e.target;
    setEmployerAddress(prevEmployerAddress => ({
      ...prevEmployerAddress,
      [name]: value
    }));

    if (name === 'postal_code') {

      dispatch(updateEntityProperty({ employerAddress: { ...mainAccountsEntity.employerAddress, ['postal_code']: value } }));
    } else {
      dispatch(updateEntityProperty({ employerAddress: { ...mainAccountsEntity.employerAddress, [name]: value } }));
    }
    if (value) {
      clearValidationError(name);
    }

  }

  const handlePassportExpiry = (e: { target: { value: string } }) => {
    const lcl_passport_expiry_date = e.target.value;
    const today = new Date();
    const minAllowedDate = new Date(getFifteenDaysFromToday());
    const enteredDate = new Date(lcl_passport_expiry_date);

    let hasError = false;

    const enteredYear = enteredDate.getFullYear();
  if (enteredYear < today.getFullYear()) {
    setPassportExpiryError({
      passport_expiry_date: 'The year must not be in the past.',
    });
    hasError = true;
  } else {
    clearValidationError('passport_expiry_date');
  }

  if (!hasError && enteredDate < minAllowedDate) {
    setPassportExpiryError({
      passport_expiry_date: 'Passport expiry date must be at least 15 days from today.',
    });
    hasError = true;
  } else if (!hasError) {
    clearValidationError('passport_expiry_date');
  }

    setValue('passport_expiry_date', lcl_passport_expiry_date); // Set form value
    trigger('passport_expiry_date');  // Trigger form validation
    
  
    setPassport_expiry_date(lcl_passport_expiry_date);
  
    if (!hasError) {
      dispatch(updateEntityProperty({ passport_expiry_date: lcl_passport_expiry_date }));
    }
  };
  
  
  
  useEffect(() => {
    setMinDate(getFifteenDaysFromToday());
  }, []);
  const getFifteenDaysFromToday = () => {
    const today = new Date();
    today.setDate(today.getDate() + 15);
    return today.toISOString().split('T')[0];
  };
  const handleCountryOfBirth = (e: any) => {
    setCountryOfBirth(e.target.value)
    dispatch(updateEntityProperty({
      birth_info: {
        countryOfBirth: e.target.value
      }
    }));
  };

  const handleCountryOfCitizenship = (e: any) => {
    setCountryOfCitizenship(e.target.value)
    dispatch(updateEntityProperty({
      citizenship: e.target.value
    }));
  };

  // useEffect(() => {
  //   // Set initial state from session storage or default to false
  //   const storedValue = sessionStorage.getItem('taxResidentInIreland');
  //   setIstaxresident(storedValue === 'true');
  // }, []);

  const handleTaxResidentChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = e.target;

    if (name === "taxResidentInIreland") {
      sessionStorage.setItem('taxResidentInIreland', JSON.stringify(checked));
      dispatch(updateEntityProperty({ ['taxResidentInIreland']: checked }));
      
      setIstaxresident(checked);
    }

    if (name === "is_tax_resident_in_another_country") {
      dispatch(updateEntityProperty({ ['is_tax_resident_in_another_country']: checked }));
      setIstaxresidentinAnotherCountry(checked);
    }
  };
  const handleEmploymentStatusChange = (e: any) => {
    const lcl_employment_status = e.target.value;
    setEmployment_status(employment_status)
    dispatch(updateEntityProperty({ employment_status: lcl_employment_status }));
  }

  const handleInvestmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputVal = e.target.value;
    const numericValue = parseFloat(inputVal.replace(/[^\d.]/g, ''));

    if (!isNaN(numericValue)) {
      setInvestmentAmount(inputVal);

      if (numericValue >= 50000) {
        setInvestError('');
        clearValidationError('investmentAmount');
      } else {
        setInvestError(numericValue ? 'Minimum value should be €50,000' : 'This field is mandatory');
        if (numericValue) {
          clearValidationError('investmentAmount');
        }
      }

      dispatch(updateEntityProperty({ investmentAmount: numericValue.toString() }));
    } else {
      setInvestmentAmount('');
      setInvestError('Invalid input');
    }
  };

  const formatCurrency = (amount: string | number | null | undefined): string => {
    if (amount === '') {
      return '';
    }
    const amountStr = amount.toString();

    const numericAmount = parseFloat(amountStr.replace(/[^\d.]/g, ''));
    if (isNaN(numericAmount)) {
      return '';
    }

    return numericAmount.toLocaleString('en-EN', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
    });
  };

  //
  const handleFileUpload = async(file: any, docType: string) => {

    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    if (!allowedFileTypes.includes(file.type)) {
      setToastMessage('Unsupported file format');
      setToastType('error');
      setToastVisible(true);
      return;
    }
    if (!accountsEntity && !pendingTaskAccountId?.[0]?.accountId) {
      return;

    }
    setIsUploadingOne(true);
    await dispatch(
      uploadDocument({
        accountId: accountsEntity || pendingTaskAccountId?.[0]?.accountId,
        file,
        docType,
      })
    )
      .then((response: any) => {
        if (response.payload.data) {
          setUploadedFile(response.payload.data);
          
          // setUploadedFile({
          //   fileData: response.payload.data,
          //   fileName: file.name, 
          // });

          // Dispatch the updated entity property
          dispatch(updateEntityProperty({
            ppsNumberDoc: response.payload.data
          }));
          setToastMessage((prev) => 'Uploaded Successfully');
          setToastType('success');
          setToastVisible(true);
          setIsUploadingOne(false);

        } else if (response?.payload?.response?.status===400){
          setIsUploadingOne(false);
          setToastMessage((prev) => 'Document upload failed!!');
          setToastType('error');
          setToastVisible(true);
          console.error('Response payload is undefined');

        }
        else {
          console.error('Unexpected response format or error:', response);
          setToastMessage('Please save the document as a smaller file size  (less than 2MB) and try and upload again.');
          setToastType('error');
          setToastVisible(true);
        }
    
        setIsUploadingOne(false);
        
      })
      .catch((error) => {
        setIsUploadingOne(false);
        console.error(`Error uploading ${docType}:`, error);
        setToastMessage((prev) => 'Please save the document as a smaller file size  (less than 2MB) and try and upload again.');
          setToastType('error');
          setToastVisible(true);
      });



  };
  const PPS_REGEX = /^\d{7}[A-Z]{1,2}$/;
  const handlePpsNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPpsnumber(value);

    if (PPS_REGEX.test(value)) {
      setPpsError('');
    } else {
      setPpsError('Invalid PPS number format. Expected format: 7 digits followed by 1 or 2 uppercase letters.');
    }
    dispatch(updateEntityProperty({ ppsNumber: value }));
    clearValidationError('ppsNumber');
  };
  //end
  const handleDeleteFile = () => {
    dispatch(updateEntityProperty({
      ppsNumberDoc: null,
    }))
    setUploadedFile(null);
  };

  const handlePassportFileUploaded = async (file: any, docType: string) => {
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    if (!allowedFileTypes.includes(file.type)) {
      setToastMessage('Unsupported file format');
      setToastType('error');
      setToastVisible(true);
      return;
    }
    if (!accountsEntity && !pendingTaskAccountId?.[0].accountId) {


      return;

    }
    setIsUploadingTwo(true);
   await  dispatch(
      uploadDocument({
        accountId: accountsEntity || pendingTaskAccountId?.[0]?.accountId,
        file,
        docType,
      })
    )
      .then((response: any) => {
        if (response.payload.data) {
          setUploadedPassport(response.payload.data);
          // setUploadedPassport({
          //   fileData: response.payload.data,
          //   fileName: file.name, // Capture the file name
          // });

          const updatedJointDetails = { ...mainAccountsEntity, passportDoc: response.payload.data };


          dispatch(updateEntityProperty({
            passportDoc: response.payload.data,
          }));
          setToastMessage((prev) => 'Uploaded Successfully');
          setToastType('success');
          setToastVisible(true);
          setIsUploadingTwo(false);

        } else if (response?.payload?.response?.status===400) {
          console.log(response.payload.response.status);
          
          setIsUploadingTwo(false);
          console.error('Response payload is undefined');
          setToastMessage((prev) => 'Document upload failed!!');
          setToastType('error');
          setToastVisible(true);
        }
        else {
          console.error('Unexpected response format or error:', response);
          setToastMessage('Please save the document as a smaller file size  (less than 2MB) and try and upload again.');
          setToastType('error');
          setToastVisible(true);
        }
    
        setIsUploadingTwo(false);
        
      })
      .catch((error) => {
       
        setIsUploadingTwo(false);
        console.error(`Error uploading ${docType}:`, error);
        setToastMessage((prev) => 'Please save the document as a smaller file size  (less than 2MB) and try and upload again.');
          setToastType('error');
          setToastVisible(true);
      });
  }

  const handleDeletePassportFile = () => {
    dispatch(updateEntityProperty({
      passportDoc: null,
    }))
    setUploadedPassport(null);
  };

  const handleKeyDown = (event: any) => {
    const allowedKeys = /[0-9+\b]/;
    if (!allowedKeys.test(event.key) && event.keyCode !== 8) {
      event.preventDefault();
    }
  };

  const extractFileName = (fileData: string): string => {
    const prefix = 'SW_';
    const prefixIndex = fileData.indexOf(prefix);
    if (prefixIndex !== -1) {

      // Extract the part of the string after the prefix
      return fileData.substring(prefixIndex + prefix.length);
    }
    // Return the original string if prefix is not found
    return fileData;
  };
  

  useEffect(() => {
    dispatch(getCountriesList());
  }, [dispatch]);
  const [minDate, setMinDate] = useState('');

  return (
    <div>
      <CommonToast
        visible={toastVisible}
        onClose={() => setToastVisible(false)}
        title={toastType === 'success' ? 'Success' : 'Error'}
        description={toastMessage}
        type={toastType}
        isAutoClose={true}


      />
      <Row>
        <Col md="12" className="form-heading-custom pt-3 pb-3">
          Primary Applicant Details
        </Col>
        <Row>
          <Col md="12">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span>
                <span> Title</span>
              </Label>

              <Input
                type="select"
                name="accountHolderTitle"
                id="accountHolderTitle"
                className="custom-from-input"
                value={nameTitle}
                defaultValue={mainAccountsEntity?.accountHolderTitle}
                onChange={e => handleValueChange(e)}
              >
                <option>Mr</option>
                <option>Mrs</option>
                <option>Ms</option>
                <option>Dr</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md="12" className="">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span>
                <span> First Name </span>{' '}
              </Label>
              <Input
                className="custom-from-input-readonly"
                type="text"
                name="accountHolderName"
                id="accountHolderName"
                required
                value={accountHolderName?.charAt(0).toUpperCase() + accountHolderName?.slice(1).toLowerCase()}
                onChange={e => handleValueChange(e)}
                placeholder="enter your name"
                readOnly
              />

              {validationErrors && accountHolderName === '' && <div className="text-danger">{validationErrors?.name}</div>}
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>Middle Name</span>
              </Label>
              <Input
                className="custom-from-input"
                type="text"
                name="middle_name"
                id="middle_name"
                required
                value={middleName}
                defaultValue={mainAccountsEntity?.middle_name?.charAt(0).toUpperCase() + mainAccountsEntity?.middle_name?.slice(1).toLowerCase()}
                onChange={e => handleValueChange(e)}
                placeholder="enter your name"
              />

              <span className="text-black">Please provide your middle name if it is included in your passport</span>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span>
                <span> Last Name</span>
              </Label>

              <Input
                type="text"
                name="accountHolderSurName"
                id="accountHolderSurName"
                value={lastName?.charAt(0).toUpperCase() + lastName?.slice(1).toLowerCase()}
                required
                onChange={e => handleValueChange(e)}
                className="custom-from-input-readonly"
                readOnly
              />
            </FormGroup>
          </Col>
          
          <Col md="12">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span>
                <span> Your Email</span>
              </Label>
              <Input
                className="custom-from-input-readonly"
                type="email"
                name="accountHolderEmail"
                required
                id="accountHolderEmail"
                value={email}
                // defaultValue={mainAccountsEntity?.accountHolderEmail}
                onChange={e => handleValueChange(e)}
                placeholder="enter your email"
                readOnly
              />
              {validationErrors && email === '' && <div className="text-danger">{validationErrors?.email}</div>}
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span >*</span>
                <span> Phone Number</span>
              </Label>
              <Input
                className="custom-from-input-readonly"
                type="tel"
                name="accountHolderMobileNumber"
                id="phonenumber"
                value={phone}
                // defaultValue={mainAccountsEntity?.accountHolderMobileNumber}
                onChange={e => handleChangePhone(e)}
                placeholder="enter your phonenumber"
                readOnly
              />

              {validationErrors && phone === '' && <div className="text-danger">{validationErrors.phone}</div>}
            </FormGroup>
          </Col>
          </Row>
          <Row>
          <FormGroup>
            <Col md="12" className="under-border-div mt-3 mb-3"></Col>
          </FormGroup>
        </Row>
        <Row>
          <Col md="12" className="from-sub-heading-custom">Relationship Details</Col>
          <Col md="12 pt-3">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span>
                <span>Marital Status</span>
              </Label>

              <Input
                type="select"
                name="accountHolderMaritalStatus"
                id="accountHolderMaritalStatus"
                className="custom-from-input"
                value={maritalstatus}
                defaultValue={mainAccountsEntity?.accountHolderMaritalStatus}
                onChange={e => handleValueChange(e)}
              >
                <option value={'Married'}>Married</option>
                <option value={'Single'}>Single</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span>
                <span>Dependents</span>
              </Label>

              <Input
                type="select"
                name="accountHolderDependents"
                id="accountHolderDependents"
                className="custom-from-input"
                value={String(dependents)}
                defaultValue={mainAccountsEntity?.accountHolderDependents}
                onChange={e => handleValueChange(e)}
              >
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md="12">
          <FormGroup>
            <Col md="12" className="under-border-div mt-3 mb-3"></Col>
          </FormGroup>
        </Col>
        <Col md="12" className="from-sub-heading-custom">Investment Account Details</Col>
          <Col md="12 pt-3">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span> Account Type
              </Label>
              <Input
                type="text"
                name="accountHolderType"
                id="accounttype"
                className="custom-from-input-readonly"
                value={mainAccountsEntity?.accountHolderType}
                onChange={handleValueChange}
                readOnly
              ></Input>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span >*</span> Investment Amount
              </Label>
              <Input
                type="text"
                name="investmentAmount"
                id="investmentAmount"
                placeholder="Enter your amount"
                className="custom-from-input"
                inputMode="numeric"

                value={formatCurrency(investmentAmount)}
                defaultValue={mainAccountsEntity?.investAmount}
                onChange={e => handleInvestmentChange(e)}
              />
              {investError && <div className="text-danger">{investError}</div>}
              {validationErrors.investmentAmount && <div className="text-danger">{validationErrors.investmentAmount}</div>}            </FormGroup>
          </Col>
        </Row>
        {/* <Row>
          <FormGroup>
            <Col md="12" className="under-border-div mt-3 mb-3"></Col>
          </FormGroup>
        </Row> */}
        {/* <Col md="12" className="from-sub-heading-custom">Address Details</Col> */}
{/* 
        <Row>
          <Col md="12 pt-3">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span> House Street Address
              </Label>
              <Input
                type="text"
                name="addressLine1"
                id="addressLine1"
                className="custom-from-input"
                value={addressLine1}
                defaultValue={mainAccountsEntity?.addressLine1}
                onChange={e => handleValueChange(e)}
              />
              {validationErrors.addressLine1 && <div className="text-danger">{validationErrors.addressLine1}</div>}{' '}
            </FormGroup>
          </Col>{' '}
          <Col md="12">
            <FormGroup>
              <Label className="label-custom-form-input">
                Address Line 2
              </Label>

              <Input
                type="text"
                name="addressLine2"
                id="addressLine2"
                className="custom-from-input"
                value={addressLine2}
                defaultValue={mainAccountsEntity?.addressLine2}
                onChange={e => handleValueChange(e)}
              />
            </FormGroup>
          </Col>{' '}

          <Col md="12">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span >*</span>
                <span> Town / City</span>
              </Label>

              <Input
                type="text"
                name="townOrCity"
                id="townOrCity"
                className="custom-from-input"
                value={townOrCity}
                defaultValue={mainAccountsEntity?.townOrCity}
                onChange={e => handleValueChange(e)}
              />
              {validationErrors.townOrCity && <div className="text-danger">{validationErrors.townOrCity}</div>}
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span> County
              </Label>

              <Input
                type="select"
                name="state_or_province"
                id="state_or_province"
                className="custom-from-input"
                value={state_or_province}
                defaultValue={mainAccountsEntity?.state_or_province}
                onChange={e => handleValueChange(e)}
              >
               <> <option value={mainAccountsEntity?.state_or_province}>{mainAccountsEntity?.state_or_province ||''}</option></> 
                {stateList && stateList.map((item: any, index) => (
                  <option key={index} value={item?.name}>{item?.name}</option>
                ))}
              </Input>
              {validationErrors.state_or_province && <div className="text-danger">{validationErrors.state_or_province}</div>}  
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
            <Label className="label-custom-form-input"><span>*</span> Country</Label>
            <Input
              type="text"
              name="country"
              id="country"
              className="custom-from-input"
              value={country}
              onChange={handleValueChange}
              readOnly
            />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label className="label-custom-form-input"><span>*</span> Post Code</Label>
              <Input
                type="text"
                name="postcode"
                id="postcode"
                className="custom-from-input"
                value={postcode}
                defaultValue={mainAccountsEntity?.postcode}
                onChange={e => {
                  const uppercaseValue = e.target.value.toUpperCase(); 
                  const name = e.target.name;
                  handleValueChange({ ...e, target: { ...e.target, value: uppercaseValue, name } }); 
                }}
                style={{ textTransform: 'uppercase' }}
              />
              {validationErrors.postcode && <div className="text-danger">{validationErrors.postcode}</div>}{' '}
            </FormGroup>
          </Col>
          <Col md="12">
              <Label className="label-custom-form-input">
                <span >*</span>
                <span> Proof of Address Upload</span>
              </Label>
                {isUploadingOne ? (
                  <div style={{ width: '50px' }}>
                    <Lottie animationData={animationData} loop autoplay style={{ color: '#FF0000' }} />
                    <span style={{ color: '#607980' }}>Uploading...</span>
                  </div>
                ) : (
                  <>
                    {mainAccountsEntity?.ppsNumberDoc || uploadedFile ? (
                      <div className="custom-from-input-upload upload-button-new-style">
                            <div className='uploaded-file-style'>
                              <div className="d-flex text-uploaded-document cursor-pointer strong" style={{height:'30px'}}>
                                <div className='align-content-center'>
                                  <FileEarmarkMedicalFill className="text-uploaded-document" style={{ fontSize: '20px' }} />
                                </div>
                                <div className="pt-1" style={{ fontSize: '16px' }}>{extractFileName(mainAccountsEntity.ppsNumberDoc)  } &nbsp;&nbsp;</div>
                              </div>
                              <div className='align-content-center p-0' onClick={handleDeleteFile} style={{ cursor: 'pointer',height:'30px'  }}>
                                <Trash className="trash-btn" style={{ fontSize: '16px' }}  />
                              </div>
                            </div>
                      </div>
                    ) : (
                      <>
                        <DropZone onFileUploaded={handleFileUpload} docType={'PPC'} />
                          {validationErrors.uploadedFile && <div className="text-danger">{validationErrors.uploadedFile}</div>}
                          <div className="text-black">
                          The printed address on your proof of address document needs to match the address provided earlier in the application.  If your Drivers License matches this address, please use this as your proof of address document.  If not please upload a recent bank statement or utility bill in PNG,JPG, JPEG OR PDF file format.
                          </div>
                      </>
                    )}
                  </>
                )}
          </Col>
          <Col md="12">
          <FormGroup>
            <Col md="12" className="under-border-div mt-3 mb-3"></Col>
          </FormGroup>
        </Col>
        <Col md="12" className="from-sub-heading-custom pt-2">Social Security Details</Col>
          <Col md="12" className="pt-3">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span>
                <span> PPS Number</span>
              </Label>
                <Input
                  type="text"
                  name="ppsNumber"
                  id="ppsNumber"
                  className="custom-from-input"
                  value={ppsNumber}
                  defaultValue={mainAccountsEntity?.ppsNumber}
                  onChange={e => handlePpsNumberChange(e)}
                />
                {ppsError && <div className="error-message" style={{ color: 'red' }}>{ppsError}</div>}

                {validationErrors.ppsNumber && <div className="text-danger">{validationErrors.ppsNumber}</div>}{' '}
            </FormGroup>
          </Col>
         
          <Col md="12">
          <FormGroup>
            <Col md="12" className="under-border-div mt-3 mb-3"></Col>
          </FormGroup>
        </Col>
        <Col md="12" className="from-sub-heading-custom pt-2">Tax Status</Col>
          <Col md="12" className="pt-3">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span>
                <span> Passport Number</span>
              </Label>
              <Input
                type="text"
                name="passport_number"
                id="passport_number"
                className="custom-from-input"
                value={passport_number}
                defaultValue={mainAccountsEntity?.passport_number}
                onChange={e => handleValueChange(e)}
              />
              {validationErrors.passport_number && <div className="text-danger">{validationErrors.passport_number}</div>}{' '}
            </FormGroup>
          </Col>
          <Col md="12" className="">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span> Country Issued
              </Label>
              <Input
                type="select"
                name="issued_country"
                id="issued_country"
                className="custom-from-input"
                value={mainAccountsEntity?.issued_country}
                onChange={handleValueChange}
              >
                <> <option value={mainAccountsEntity?.issued_country}>{mainAccountsEntity?.issued_country|| "Ireland"}</option></>
                {countryList && countryList?.filter((obj: any) => obj.name !== 'Ireland')?.map((item: any, index) => (
                  <option key={index} value={item.name}>{item.name}</option>
                ))}
              </Input>


              {validationErrors && !issued_country && <div className="text-danger">{validationErrors.issued_country}</div>}
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span>
                <span> Passport Expiry Date</span>
              </Label>

              <Form className="pt-2">

                <ValidatedDatePickerField
                    onChange={(date) => {
                      handlePassportExpiry({ target: { value: date } }); // Properly invoke the function
                    }}
                    defaultdate={mainAccountsEntity?.passport_expiry_date||passport_expiry_date}
                    getFieldState={getFieldState}
                    register={register}
         
                  />
              </Form>
              {passportexpiryError && passportexpiryError.passport_expiry_date && (
                  <p className="text-danger">{passportexpiryError.passport_expiry_date}</p>
                )}
                {validationErrors.passport_expiry_date && <div className="text-danger">{validationErrors.passport_expiry_date}</div>}{' '}
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span>
                <span> Passport Document Upload</span>
              </Label>

                {isUploadingTwo ? (
                  <div style={{ width: '50px' }}>
                    <Lottie animationData={animationData} loop autoplay />
                    <span style={{ color: '#607980' }}>Uploading...</span>
                  </div>
                ) : (
                  <>
                    {mainAccountsEntity?.passportDoc || uploadedPassport? (
                      <div className="custom-from-input-upload upload-button-new-style">
                        
                            <div className='uploaded-file-style'>
                              <div className="d-flex text-uploaded-document cursor-pointer  strong" style={{height:'30px'}}>
                              <div className='align-content-center'>
                                <FileEarmarkMedicalFill className="text-uploaded-document" style={{ fontSize: '20px' }} />
                                </div>                          
                              <div className="pt-1"style={{ fontSize: '16px' }}>{extractFileName(mainAccountsEntity?.passportDoc) }&nbsp;&nbsp;</div>
                              </div>
                              <div className="align-content-center p-0" onClick={handleDeletePassportFile} style={{ cursor: 'pointer',height:'30px' }}>
                                <Trash className="trash-btn" style={{ fontSize: '16px' }}/>
                              </div>
                            </div>                      
                      </div>
                    ) : (
                      <>
                        
                        <DropZone onFileUploaded={handlePassportFileUploaded} docType={'PASSPORT'} />
                          {validationErrors.uploadedPassport && <div className="text-danger">{validationErrors.uploadedPassport}</div>}{' '}
                          <div className="text-black">
                          Please upload a recent passport document in PNG, JPG, JPEG, or PDF file format. This should include the main page and the signature page, which must be signed.
                          </div>
                      </>
                        
                    )}
                  </>
              )}
              </FormGroup>
          </Col>

          <Col md="12">
            <FormGroup>
              <Label className="label-custom-form-input">Country of Birth</Label>
              <Input
                type="select"
                name="countryOfBirth"
                id="countryOfBirth"
                className="custom-from-input-readonly"
                defaultValue={mainAccountsEntity?.birth_info?.countryOfBirth}
                onChange={e => handleCountryOfBirth(e)}
              >
                <> <option value={mainAccountsEntity?.birth_info?.countryOfBirth}>{mainAccountsEntity?.birth_info?.countryOfBirth || "Ireland"}</option></>
                {countryList && countryList?.filter((obj: any) => obj.name !== 'Ireland')?.map((item: any, index) => (
                  <option key={index} value={item.name}>{item.name}</option>
                ))}
              </Input>
            </FormGroup>
          </Col>

          <Col md="12">
            <FormGroup>
              <Label className="label-custom-form-input">Country of Citizenship</Label>
              <Input
                type="select"
                name="countryOfCitizenship"
                id="countryOfCitizenship"
                className="custom-from-input-readonly"
                value={mainAccountsEntity?.citizenship} // Set value instead of defaultValue
                onChange={e => handleCountryOfCitizenship(e)}
              >
                <> <option value={mainAccountsEntity?.citizenship}>{mainAccountsEntity?.citizenship|| findCountryNameByKey("Ireland")}</option></>
                {countryList && countryList?.filter((obj: any) => obj.name !== 'Ireland')?.map((item: any, index) => (
                  <option key={index} value={item.name}>{item.name}</option>
                ))}
              </Input>
            </FormGroup>
          </Col>

          <Col md="12">
            <FormGroup>
              <Col md="12" className="under-border-div mt-3 mb-3"> </Col>
            </FormGroup>
          </Col>
          <Col md="12" className="from-sub-heading-custom">Passport Details</Col>
              <Col md="12" className='pt-3'>
                <FormGroup>
                  <Label check className="label-custom-form-check-box">

                    <Input
                      type="checkbox"
                      name="taxResidentInIreland"
                      id="taxResidentInIreland"
                      className="input-account-custom-bgnone me-1"
                      checked={istaxresident}
                      // defaultChecked={mainAccountsEntity?.taxResidentInIreland}
                      defaultValue={mainAccountsEntity?.taxResidentInIreland}
                      onChange={handleTaxResidentChange}
                    />
                    I am only tax resident in Ireland
                  </Label>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label check className="label-custom-form-check-box">

                    <Input
                      type="checkbox"
                      name="is_tax_resident_in_another_country"
                      id="is_tax_resident_in_another_country"
                      className="input-account-custom-bgnone me-1"
                      checked={is_tax_resident_in_another_country}
                      // defaultChecked={mainAccountsEntity?.taxResidentInIreland}
                      defaultValue={mainAccountsEntity?.is_tax_resident_in_another_country}
                      onChange={handleTaxResidentChange}
                    />
                    I am also a tax resident in another country
                  </Label>
                </FormGroup>
              </Col>
              {is_tax_resident_in_another_country === true ? (
                <Col md="12">
                  <FormGroup>
                    <Label className="label-custom-form-input"><span>*</span> Other Tax Residence Details</Label>

                    <Input
                      type="text"
                      name="othertaxresidence"
                      id="othertaxresidence"
                      className="custom-from-input"
                      value={othertaxresidence}
                      defaultValue={mainAccountsEntity?.othertaxresidence}
                      onChange={handleValueChange}
                    />
                    <span className="text-black">
                      Please list any other countries where you are tax resident, including your Tax Identification Number for that country e.g. United Kingdom
                    </span>
                    {validationErrors?.othertaxresidence && <div className="text-danger">{validationErrors?.othertaxresidence}</div>}

                  </FormGroup>
                </Col>
              ) : null}
          </Row> */}
          <Row>
          <Col md="12">
          <FormGroup>
            <Col md="12" className="under-border-div mt-3 mb-3"></Col>
          </FormGroup>
        </Col>
       <Col md="12" className="from-sub-heading-custom">Address Details</Col>
       <Row>
         <Col md="12 pt-3">
           <FormGroup>
             <Label className="label-custom-form-input">
               <span>*</span> House Street Address
             </Label>
             <Input
               type="text"
               name="addressLine1"
               id="addressLine1"
               className="custom-from-input"
               value={addressLine1}
               defaultValue={mainAccountsEntity?.addressLine1}
               onChange={e => handleValueChange(e)}
             />
             {validationErrors.addressLine1 && <div className="text-danger">{validationErrors.addressLine1}</div>}{' '}
           </FormGroup>
         </Col>{' '}
         <Col md="12">
           <FormGroup>
             <Label className="label-custom-form-input">
               Address Line 2
             </Label>
       
             <Input
               type="text"
               name="addressLine2"
               id="addressLine2"
               className="custom-from-input"
               value={addressLine2}
               defaultValue={mainAccountsEntity?.addressLine2}
               onChange={e => handleValueChange(e)}
             />
           </FormGroup>
         </Col>{' '}
       
         <Col md="12">
           <FormGroup>
             <Label className="label-custom-form-input">
               <span >*</span>
               <span> Town / City</span>
             </Label>
       
             <Input
               type="text"
               name="townOrCity"
               id="townOrCity"
               className="custom-from-input"
               value={townOrCity}
               defaultValue={mainAccountsEntity?.townOrCity}
               onChange={e => handleValueChange(e)}
             />
             {validationErrors.townOrCity && <div className="text-danger">{validationErrors.townOrCity}</div>}
           </FormGroup>
         </Col>
         <Col md="12">
           <FormGroup>
             <Label className="label-custom-form-input">
               <span>*</span> County
             </Label>
       
             <Input
               type="select"
               name="state_or_province"
               id="state_or_province"
               className="custom-from-input"
               value={state_or_province}
               defaultValue={mainAccountsEntity?.state_or_province}
               onChange={e => handleValueChange(e)}
             >
              <> <option value={mainAccountsEntity?.state_or_province}>{mainAccountsEntity?.state_or_province ||''}</option></> 
               {stateList && stateList.map((item: any, index) => (
                 <option key={index} value={item?.name}>{item?.name}</option>
               ))}
             </Input>
             {validationErrors.state_or_province && <div className="text-danger">{validationErrors.state_or_province}</div>}  
           </FormGroup>
         </Col>
         <Col md="12">
           <FormGroup>
           <Label className="label-custom-form-input"><span>*</span> Country</Label>
           <Input
             type="text"
             name="country"
             id="country"
             className="custom-from-input"
             value={country}
             // defaultValue={country}
             onChange={handleValueChange}
             readOnly
           />
           </FormGroup>
         </Col>
         <Col md="12">
           <FormGroup>
             <Label className="label-custom-form-input"><span>*</span> Post Code</Label>
             <Input
               type="text"
               name="postcode"
               id="postcode"
               className="custom-from-input"
               value={postcode}
               defaultValue={mainAccountsEntity?.postcode}
               onChange={e => {
                 const uppercaseValue = e.target.value.toUpperCase(); 
                 const name = e.target.name;
                 handleValueChange({ ...e, target: { ...e.target, value: uppercaseValue, name } }); 
               }}
               style={{ textTransform: 'uppercase' }}
             />
             {validationErrors.postcode && <div className="text-danger">{validationErrors.postcode}</div>}{' '}
           </FormGroup>
         </Col>
         <Col md="12">
             <FormGroup>
               <Label className="label-custom-form-input">Country of Birth</Label>
               <Input
                 type="select"
                 name="countryOfBirth"
                 id="countryOfBirth"
                 className="custom-from-input-readonly"
                 defaultValue={mainAccountsEntity?.birth_info?.countryOfBirth}
                 onChange={e => handleCountryOfBirth(e)}
               >
                 <> <option value={mainAccountsEntity?.birth_info?.countryOfBirth}>{mainAccountsEntity?.birth_info?.countryOfBirth || "Ireland"}</option></>
                 {countryList && countryList?.filter((obj: any) => obj.name !== 'Ireland')?.map((item: any, index) => (
                   <option key={index} value={item.name}>{item.name}</option>
                 ))}
               </Input>
             </FormGroup>
           </Col>
         
           <Col md="12">
             <FormGroup>
               <Label className="label-custom-form-input">Country of Citizenship</Label>
               <Input
                 type="select"
                 name="countryOfCitizenship"
                 id="countryOfCitizenship"
                 className="custom-from-input-readonly"
                 value={mainAccountsEntity?.citizenship} // Set value instead of defaultValue
                 onChange={e => handleCountryOfCitizenship(e)}
               >
                 <> <option value={mainAccountsEntity?.citizenship}>{mainAccountsEntity?.citizenship|| findCountryNameByKey("Ireland")}</option></>
                 {countryList && countryList?.filter((obj: any) => obj.name !== 'Ireland')?.map((item: any, index) => (
                   <option key={index} value={item.name}>{item.name}</option>
                 ))}
               </Input>
             </FormGroup>
           </Col>
         </Row>
          </Row>
            <Helmet> 
            <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/145768795.js" ></script>
            </Helmet>
        </Row>
    </div>
  );
};
export default forwardRef(PrimaryApplication);
