import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const generatePDF = async (
  containerId: string,
  margins = { top: 5, bottom: 5, left: 5, right: 5 }
): Promise<string | null> => {
  try {
    const element = document.getElementById(containerId);
    if (!element) {
      throw new Error(`Element with ID "${containerId}" not found.`);
    }

    // Generate high-quality canvas
    const canvas = await html2canvas(element, { scale: 2 });

    // Convert canvas to image
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
    const imgData = canvas.toDataURL('image/jpeg', 0.9); // High-quality compression

    // Increased width for better readability
    const pdfWidth = 800; // Increased width
    const pdfHeight = 1100; // Adjusted for better fit

    // Scale image to fit PDF width
    const scaleFactor = pdfWidth / imgWidth;
    const scaledHeight = imgHeight * scaleFactor;

    // Split height into 3 equal parts
    const sectionHeight = imgHeight / 3;

    // Create jsPDF instance
    const pdf = new jsPDF({
      orientation: 'p',
      unit: 'px',
      format: [pdfWidth, pdfHeight],
      compress: true,
    });

    const createSection = (startY: number): string => {
      const sectionCanvas = document.createElement('canvas');
      sectionCanvas.width = imgWidth;
      sectionCanvas.height = sectionHeight;
      const ctx = sectionCanvas.getContext('2d');
      ctx?.drawImage(canvas, 0, -startY, imgWidth, imgHeight);
      return sectionCanvas.toDataURL('image/jpeg', 0.9); // Maintain quality
    };

    // Add each section as a separate page
    for (let i = 0; i < 3; i++) {
      if (i > 0) pdf.addPage();
      const sectionImg = createSection(i * sectionHeight);
      pdf.addImage(sectionImg, 'JPEG', margins.left, margins.top, pdfWidth - margins.left - margins.right, pdfHeight - margins.top - margins.bottom);
    }

    // Convert to Blob and check file size
    const pdfBlob = pdf.output('blob');
    if (pdfBlob.size > 10 * 1024 * 1024) { // Ensure size is under 10MB
      console.warn('PDF size exceeds 10MB, consider further compression.');
      return null;
    }

   // Convert the PDF to Base64
   const pdfDataUri = pdf.output('datauristring'); // Base64 string for the PDF
   const base64String = pdfDataUri.split(',')[1]; // Extract only the base64 part

    console.log('Generated PDF in Base64 format:', base64String);

    return base64String;
  } catch (error) {
    console.error('Error generating or converting PDF:', error);
    return null;
  }
};


