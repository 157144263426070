/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getBankList, getCountryList, getEntity, getState, reset, signature, submitW8BenForm, updateEntity, updateEntityProperty, uploadPDF } from './accounts.reducer';
import './style_account.css';
import './account.scss';
import { getCustomerDetails } from '../customer/customer.reducer';
import { SecondDoc } from './SecondDoc';
import FormDoc from './formDoc';
import SosDoc from './SosDoc';
import PrimaryApplication from './primary-Application';
import Pep from './pep';
import SecondaryAplicaion from './secondary-Application';
import 'react-toastify/dist/ReactToastify.css';
import InteractiveBrokers from './interactive-brokers';
import AccountSummary from './account-summary';
import W8benSecondary from './w8ben_secondary';
import CommonToast from 'app/modules/components/CommonToast';
import { getPendingTask } from 'app/modules/profile/profile.reducer';
import { saveInitialPath } from 'app/modules/account/register/register.reducer';
import NetWorthAnnualIncome from './net-worth-annual-income';
import InitialRequirementCheck from './initial-requirement-check';
import AntiMoney from './anti-money';
import FinancialServices from './financial-Services';
import { PROGRESS_PERCENTAGE } from 'app/config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import PrimaryAddressDetails from './primary-address-details';
import Welcome from './welcome';
import PrimaryDocumentDetails from './primary-document-details';
import { generatePDF } from 'app/modules/components/GeneratePdf/GeneratePdf';
export const Accounts: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isAllCheckBoxesSelected, setIsAllCheckBoxesSelected] = useState(false);
  const handleCheckBoxesChange = (checkboxesSelected: boolean) => {
    setIsAllCheckBoxesSelected(checkboxesSelected);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const signatureData = useAppSelector(state => state.accounts.signatureData);
  const accountsEntity = useAppSelector(state => state.accounts.entity.accountId);
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const { last_level } = useAppSelector(state => state.accounts.entity);
  const customerDetails = useAppSelector(state => state.customer.entities);
  const [docConfirmation, setDocConfirmation] = useState({
    statement: false,
  });
  const topSectionHolder = document.querySelector('#topSectionHolder');
  const [docConfirmation3, setDocConfirmation3] = useState({
    statement3: false,
  });

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [customerId, setCustomerId] = useState()
  const [showModal, setShowModal] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
  const [accountIdNum, setAccountId] = useState('');
  const [AccountType, setAccountType] = useState(signatureData?.account_type || 'Individual');
  const [investmentAmount, setInvestAmount] = useState(0);
  const [totalValueError, setTotalValueError] = useState('');
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [middle_name, setMiddleName] = useState('');
  const [country, setCountry] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [secondvalidationErrors, setsecondValidationErrors] = useState<IValidationErrors>({});
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const [showdivprogress, setShowdivprogress] = useState(0);
  const [showdivper, setShowdivper] = useState(0);
  const [saveToast, setSaveToast] = useState(false);


  const scrollToTop = () => {
    setTimeout(() => {
      if (topSectionHolder) {
        topSectionHolder.scrollIntoView({ behavior: 'smooth' });
      }
    }, 50);
  }

  useEffect(() => {
    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {

      // navigate('/accounts');
      if (window.location.pathname === '/accounts') {
        navigate('/accounts')
      } else {
        if (window.location.pathname === '/company') {
          navigate('/company')
        } else if (window.location.pathname === '/dashboard') {
          navigate('/dashboard')
        } else if (window.location.pathname === '/admin-profile') {
          navigate('/admin-profile')
        }
      }
    } else {
      dispatch(saveInitialPath(window.location.pathname))
      navigate('/login');
    }
  }, []);
  useEffect(() => {
    const accessToken = localStorage.getItem('jhi-authenticationToken');
    if (accessToken) {
      const tokenParts = accessToken?.split('.');
      if (tokenParts?.length === 3) {
        try {
          const payload = JSON.parse(atob(tokenParts[1]));
          if (payload?.sub) {
            const { sub } = payload;
            setCustomerId(sub)
          }
        } catch (error) { /* empty */ }
      }
    }
  }, []);
  useEffect(() => {
    if (customerDetails) {
      setName(customerDetails?.firstName || '');
      setLastName(customerDetails?.lastName || '');
      setEmail(customerDetails?.email || '');
      setPhone(customerDetails?.phone || '');
    }
  }, [customerDetails]);


  const [pendingTaskAccountId, setPendingTaskAccountId] = useState([])
  useEffect(() => {
    dispatch(getPendingTask({}))
      .then((response: any) => {

        setPendingTaskAccountId(response?.payload?.data?.pending_accounts)
      })
  }, [])

  const toggle = () => {
    setOpen(value => !value);
    navigate('/dashboard');
    window.open("https://www.starlightwealth.ie", "_blank", "noopener,noreferrer");
  };

  const toggle2 = () => {
    setOpen2(value2 => !value2);
  };
  const toggle3 = () => {
    setOpen3(value3 => !value3);
  };
  const toggle4 = () => {
    setOpen4(value4 => !value4);
  };
  const toggle5 = () => {
    setOpen5(value5 => !value5);
  };
  const toggle6 = () => {
    setOpen6(value6 => !value6);
  };
  const toggle7 = () => {
    setOpen7(value7 => !value7);
  };

  const togglesave = () => {
    setSaveToast(false);
  }
  const togglelogout = () => {
    setSaveToast(false);
    navigate('/logout');
  }

  const [employment_status, setEmploymentStatus] = useState('EMPLOYED');

  const [FormData2, setFormData2] = useState({
    nametitle: 'Mr',
    firstname: '',
    surname: '',
    middleName: '',
    middleInitial: '',
    maritalstatus: 'Married',
    dependents: 1,
    mobilenumber: '',
    addressLine1: '',
    addressLine2: '',
    townorcity: '',
    country: 'Ireland',
    postcode: '',
    ppsnumber: '',
    passportNumber: '',
    istaxresident: false,
    othertaxresidence: '',
    employment_status: 'EMPLOYED',
    occupation: '',
    employername: '',
    employerbusiness: '',
    employerAddress: {
      country: 'Ireland',
      state: 'Galway',
      city: "",
      postal_code: "",
      street: "",
    },
    isapubliclylistedcompany: 'false',
    employeeDocument: '',
    employerCompany: '',
    passport_number: '',
    passport_expiry_date: '',
    issued_country: 'Ireland',
    state_or_province: 'Galway',
  });

  const [newcitizen, setnewcitizen] = useState({
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    stateOrProvince: 'Galway',
    zipOrPostalCode: '',
    birth_info: {
      countryOfBirth: 'Ireland',
    },
    country: 'Ireland',
  });
  const [formData4, setFormData4] = useState({
    inverstmentfromemploymentincome: '',
    invertmentfrompropertygains: '',
    inverstmentfromgifts: '',
    inverstmentfrominverstmentgains: '',
    inverstmentfromothers: '',
    inverstmentfromothersinfo: '',
  });
  const [FormDataNetworth, setFormDataWorth] = useState({
    totalnetworth: '',
    liquidnetworth: '',
    totalnetincome: '',
  });
  const [FormDataFamily, setFormDataFamily] = useState({
    isPep: false,
    familyPep: false,
    isFamilyPep: false,
    familymemberpepdetails: '',
  });
  const [FormDataFamliyFinance, setFormDataFamliyFinance] = useState({
    employedfinancialservicefirm: false,
    whoemployedfinancialservicefirm: '',
    familymemberrealtionship: '',
    complianceofficername: '',
    complianceofficercontactnumber: '+353',
    complianceofficeremail: '',
  });

  const [interactive, setInteractive] = useState({

    ib_customer_agreement: mainAccountsEntity.ib_customer_agreement || false,
    ib_client_financial_advisor: mainAccountsEntity.ib_client_financial_advisor || false,
    elt: mainAccountsEntity.elt || false,
    ibsd_notification: mainAccountsEntity.ibsd_notification || false,
    lla: mainAccountsEntity.lla || false,
  })

  const [secondaryform, setsecondaryform] = useState({
    jointnametitle: 'Mr',
    jointfirstname: '',
    jointemail: '',
    jointsurname: '',
    jointmiddleName: '',
    jointdob: '',
    jointmaritalstatus: 'Married',
    jointdependents: 1,
    joint_username: '',
    jointmobilenumber: '+353',
    jointaddressline1: '',
    jointaddressline2: '',
    jointtownorcity: '',
    jointcountry: 'Ireland',
    jointpostcode: '',
    jointppsnumber: '',
    dependendPassportNumber: '',
    jointistaxresident: false,
    jointothertaxresidence: '',
    jointemploymentstatus: 'EMPLOYED',
    jointoccupation: '',
    jointemployername: '',
    jointemployerbusiness: '',
    jointemployeraddress: {
      country: "Ireland",
      state: 'Galway',
      city: '',
      postal_code: '',
      street: "",
    },
    jointpassport_expiry_date: '',
    jointisapubliclylistedcompany: 'false',
    employeeDocument: '',
    jointemployerCompany: '',
    jointissuedcountry: 'Ireland',
    jointstateorprovince: "Galway",
    jointcountryofbirth: 'Ireland',
  });

  const [uploadedFile, setUploadedFile] = useState<string | null>(null);
  const [uploadedPassport, setUploadedPassport] = useState<string | null>(null);
  const [joint_pps_doc, setUploadDependantPpc] = useState<string | null>(null);
  const [joint_passport_doc, setUploadDependantPassport] = useState<string | null>(null);

  useEffect(() => {
    const accountId = accountsEntity?.toString() || '';

    if (accountId) {
      dispatch(getEntity(accountId))
        .then((new_response: any) => {

          const data = new_response?.payload?.data;
          if (data) {

            setFormData2((prev) => ({
              ...prev,
              ...data,
              ppsnumber: data.ppsNumber,
              townorcity: data.townOrCity,
              middleName: data.middle_name,
              employername: data.employerName,
              employerbusiness: data.employerBusiness,

            }));
            setsecondaryform((prev) => ({
              ...prev,
              ...data.joint_details,
            }));
            setUploadDependantPpc(data.joint_details);
            setUploadDependantPassport(data.joint_details);
            setUploadedFile(data.ppsNumberDoc);
            setUploadedPassport(data.passportDoc);
            setInvestAmount(data.investmentAmount);
            setFormData4((prev) => ({
              ...prev,
              ...data,
              inverstmentfromemploymentincome: data.investmentFromEmploymentIncome,
              invertmentfrompropertygains: data.investmentFromPropertyGains,
              inverstmentfromgifts: data.investmentFromGifts,
              inverstmentfrominverstmentgains: data.investmentFromInvestmentGains,
              inverstmentfromothers: data.investmentFromOthers,
              inverstmentfromothersinfo: data.inverstmentFromOthersInfo,
            }));
            setFormDataWorth((prev) => ({
              ...prev,
              ...data,
              totalnetworth: data?.totalNetWorth,
              liquidnetworth: data?.liquidNetWorth,
              totalnetincome: data?.totalNetIncome,
            }));
            setFormDataFamily((prev) => ({
              ...prev,
              ...data,

            }));
            setFormDataFamliyFinance((prev) => ({
              ...prev,
              ...data,

            }));
            setInteractive((prev) => ({
              ...prev,
              ...data,

            }));
          }
        })
        .catch((error) => {
          console.error('Failed to fetch account entity:', error);
        });
    }
  }, [accountsEntity,
    dispatch,
    setFormData2,
    setInvestAmount,
    setFormData4,
    setFormDataWorth,
    setFormDataFamily,
    setFormDataFamliyFinance,
    setInteractive,
    setsecondaryform,
    setUploadDependantPpc,
    setUploadDependantPassport,
    setUploadedFile,
    setUploadedPassport,

  ]);

  const [checkboxStates, setCheckboxStates] = useState({
    over18: false,
    beneficialOwner: false,
    residentIreland: false,
    taxResidentIreland: false,
    minimumInvestment: false,
    investmentObjective: false,
    StarlightBusinessTerms: false,
    StarlightRemuneration: false,
    StarlightWealthPrivacy: false,
    StarlightFieldConfirmation: false,
    diffMailing: false,
    diffCitizenship: false,
    bornOutside: false,
    statement: false,
  });

  const [mailing_address, setMailing_address] = useState({
    street: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  })


  const [differentData, setDifferentData] = useState({
    signed_by: '',
    signed_date: '',
    mailing_address: '',
    ssn: '',
    ftn: false,
    reference_number: '',
    beneficial_owner_residence: '',
    article_and_paragraph: '',
    rate_of_with_holding: '',
    type_of_income: '',
    additional_condition: '',
    eligibility_explanation: ''
  });

  const [jointemploymentstatus, setjointEmploymentstatus] = useState('EMPLOYED');

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const [showSourceModal, setShowSourceModal] = useState(false);

  const handleSourceCloseModal = () => {
    setShowSourceModal(false);
  };

  const handleGenerateAndUploadPDF = async (documentType: string) => {
    const pdfBase64 = await generatePDF('datauristring'); 
    
    if (pdfBase64) {
      const payload = {
        account_number: mainAccountsEntity?.accountId,
        pdf_base64: pdfBase64,
        document_type: documentType, 
      };
      dispatch(uploadPDF(payload)); 
    } else {
      console.error('Failed to generate PDF');
    }
  };
  

  const [sosPrimary_sign, setsosPrimarySign] = useState<string | null>(null);
  const [sosSecondarySign, setsosSecondarySign] = useState<string | null>(null);
  const [ibkrPrimary_sign, setibkrPrimarySign] = useState<string | null>(null);
  const [ibkrSecondarySign, setibkrSecondarySign] = useState<string | null>(null);
  const [smart_reserve_primary_sign, setsmart_reserve_primary_sign] = useState<string | null>(null);
  const [smart_reserve_SecondarySign, setsmart_reserve_SecondarySign] = useState<string | null>(null);
  const [w8benPrimary_sign, setw8benPrimary_sign] = useState<string | null>(null);
  const [w8benSecondarySign, setw8benSecondarySign] = useState<string | null>(null);

  const handleSosSignatureUpdate = (signaturetype, signatureValue) => {

    let accountId = accountsEntity?.toString() || '';
    if (!accountId && accountId === '') {
      if (signatureData?.hasOwnProperty('statement_of_suitability') && signatureData?.statement_of_suitability?.hasOwnProperty('account_id')) {
        accountId = signatureData?.statement_of_suitability?.account_id;
      } else {
        return;
      }
    }
    const payload = {
      accountId,
      signaturetype,
      signed_by: sosSecondarySign || sosPrimary_sign,
      signed_date: new Date().toISOString().split('T')[0],
    };

    try {
      dispatch(signature(payload)).then((response: any) => {

        const updatedSignature = response.payload;
        console.log("updatedSignature", response.payload);


        if (signaturetype === 'sos') {
          if (signatureValue === sosPrimary_sign) {
            setsosPrimarySign(updatedSignature?.primary_sign || sosPrimary_sign);
          } else {
            setsosSecondarySign(updatedSignature?.joint_sign || sosSecondarySign);
          }
        }

        if (updatedSignature) {
          handleUpdateNextStep(showDiv);
          handleGenerateAndUploadPDF('sos');
        }
      });
    } catch (error) { /* empty */ }
  };

  const handleSmartReserveSignatureUpdate = (signaturetype, signatureValue) => {
    if (!accountsEntity) {
      return;
    }

    const payload = {
      accountId: accountsEntity,
      signaturetype,
      signed_by: smart_reserve_SecondarySign || smart_reserve_primary_sign,
      signed_date: new Date().toISOString().split('T')[0],
    };

    try {
      dispatch(signature(payload)).then((response: any) => {


        const updatedSignature = response.payload;

        if (signaturetype === 'smart_reserve') {
          if (signatureValue === smart_reserve_primary_sign) {
            setsmart_reserve_primary_sign(updatedSignature?.primary_sign || smart_reserve_primary_sign);
          } else {
            setsmart_reserve_SecondarySign(updatedSignature?.joint_sign || smart_reserve_SecondarySign);
          }
        }
        if (updatedSignature) {
          handleUpdateNextStep(showDiv);
          handleGenerateAndUploadPDF('smart_reserve');
        }
      });
    }
    catch (error) { /* empty */ }
  };

  const handlew8benSignatureUpdate = (signaturetype, signatureValue) => {
    if (!accountsEntity) {
      return;
    }

    const formPayload = {
      signed_by: w8benPrimary_sign,
      signed_date: new Date().toISOString().split('T')[0], // Using the current date
      mailing_address: {
        street: mailing_address.street,
        city: mailing_address.city,
        state: mailing_address.state,
        postal_code: mailing_address.postal_code,
        country: mailing_address.country,
      },
      ssn: differentData.ssn,
      ftn: true,
      reference_number: differentData.reference_number,
      beneficial_owner_residence: differentData.beneficial_owner_residence,
      article_and_paragraph: differentData.article_and_paragraph,
      rate_of_with_holding: differentData.rate_of_with_holding,
      type_of_income: differentData.type_of_income,
      additional_condition: differentData.additional_condition,
      eligibility_explanation: differentData.eligibility_explanation,
    };

    try {
      dispatch(submitW8BenForm({ accountId: accountsEntity, payload: formPayload })).then((response: any) => {
        const updatedSignature = response.payload;

        if (signaturetype === 'w8ben') {
          if (signatureValue === w8benPrimary_sign) {
            setw8benPrimary_sign(updatedSignature?.primary_sign || w8benPrimary_sign);
          }
        }
        if (updatedSignature) {
          handleUpdateNextStep(showDiv);
          handleGenerateAndUploadPDF('w8ben');
        }
      });
    } catch (error) {
      setToastMessage('Unexpected error occurred. Please try again.');
      setToastType('error');
      setToastVisible(true);
    }
  };

  const handlew8benSecondarySignatureUpdate = (signaturetype, signatureValue) => {
    if (!accountsEntity) {
      return;
    }

    const formPayload = {
      signed_by: w8benSecondarySign,
      signed_date: new Date().toISOString().split('T')[0], // Using the current date
      mailing_address: {
        street: mailing_address.street,
        city: mailing_address.city,
        state: mailing_address.state,
        postal_code: mailing_address.postal_code,
        country: mailing_address.country
      },
      ssn: differentData.ssn,
      ftn: true,
      reference_number: differentData.reference_number,
      beneficial_owner_residence: differentData.beneficial_owner_residence,
      article_and_paragraph: differentData.article_and_paragraph,
      rate_of_with_holding: differentData.rate_of_with_holding,
      type_of_income: differentData.type_of_income,
      additional_condition: differentData.additional_condition,
      eligibility_explanation: differentData.eligibility_explanation,
    };

    try {
      if (customerId === mainAccountsEntity.secondary_customer_id) {
        dispatch(submitW8BenForm({ accountId: accountsEntity, payload: formPayload })).then((response: any) => {
          const updatedSignature = response.payload;

          if (signaturetype === 'w8ben') {
            if (signatureValue === w8benPrimary_sign) {
              setw8benSecondarySign(updatedSignature?.joint_sign || w8benSecondarySign);
            }
          }
          if (updatedSignature) {
            handleUpdateNextStep(showDiv);
            handleGenerateAndUploadPDF('w8ben');
          }
        });
      }
    }
    catch (error) { /* empty */ }
  };
  const handleIBSignatureUpdate = (signaturetype, signatureValue) => {
    let accountId = accountsEntity?.toString() || '';
    if (!accountId) {
      if (
        signatureData?.hasOwnProperty('statement_of_suitability') &&
        signatureData?.statement_of_suitability?.hasOwnProperty('account_id')
      ) {
        accountId = signatureData?.statement_of_suitability?.account_id;
      } else {
        return;
      }
    }

    const payload = {
      accountId,
      signaturetype,
      signed_by: ibkrSecondarySign || ibkrPrimary_sign,
      signed_date: new Date().toISOString().split('T')[0],
    };

    try {
      dispatch(signature(payload)).then((response: any) => {
        const updatedSignature = response.payload;

        if (signaturetype === 'ibkr') {
          if (signatureValue === ibkrPrimary_sign) {
            setibkrPrimarySign(updatedSignature?.primary_sign || ibkrPrimary_sign);
          } else {
            setibkrSecondarySign(updatedSignature?.joint_sign || ibkrSecondarySign);
          }
        }

        if (updatedSignature) {
          handleUpdateNextStep(showDiv);
          handleGenerateAndUploadPDF( 'ibkr');
        }
      });
    } catch (error) {
      //empty error message//
    }
  };

  interface IValidationErrors {
    [key: string]: string;
  }

  const [countryList, setCountryList] = useState<string[]>([]);
  const [bankList, setBankList] = useState<string[]>([]);

  const [stateList, setStateList] = useState<string[]>([]);
  const [showDiv, setShowDiv] = useState(last_level);

  const accessToken = localStorage.getItem('jhi-authenticationToken');
  useEffect(() => {
    if (showDiv === 2 || showDiv === 3 || showDiv === 10) {
      if (accessToken) {
        dispatch(getCountryList()).then((res) => {
          dispatch(getCustomerDetails());

          if (res.payload) {
            const countryData = res.payload.map((item: any) => item);
            setCountryList(countryData);
          }

        });
      }
    }
  }, [showDiv, dispatch]);

  useEffect(() => {
    if (showDiv === 6 || showDiv === 10) {
      if (accessToken) {
        dispatch(getBankList()).then((res) => {
          dispatch(getCustomerDetails());

          if (res.payload) {
            const bankData = res.payload.map((item: any) => item);
            setBankList(bankData);
          }

        });
      }
    }
  }, [showDiv, dispatch]);

  useEffect(() => {
    if (showDiv === 2 || showDiv === 3 || showDiv === 10) {
      dispatch(getState()).then((res) => {


        dispatch(getCustomerDetails());

        if (res.payload) {
          const stateData = res.payload.map((item: any) => item);
          setStateList(stateData);
        }
      });
    }
  }, [showDiv, dispatch]);

  const handleContinue = () => {
    setOpen7(false);

    setOpen2(value2 => !value2);

    if (open7 === false) {
      setOpen2(true);
    }
  };

  const handleUpdateNextStep = async (_showDiv: number) => {

    let accountId = mainAccountsEntity?.accountId?.toString() || sessionStorage?.getItem('accountid') || pendingTaskAccountId?.[0]?.accountId || '';
    if (!accountId && accountId === '') {
      if (signatureData?.hasOwnProperty('statement_of_suitability') && signatureData?.statement_of_suitability?.hasOwnProperty('account_id')) {
        accountId = signatureData?.statement_of_suitability?.account_id;
      } else {
        return;
      }
    }
    if (_showDiv === 2) {
      showDiv2Function(_showDiv);
    }

    if (_showDiv === 3) {
      showDiv3Function();
    }
    if (_showDiv === 4) {
      const updateBody = {
        accountId,
        investmentFromEmploymentIncome: formData4.inverstmentfromemploymentincome,
        investmentFromPropertyGains: formData4.invertmentfrompropertygains,
        investmentFromGifts: formData4.inverstmentfromgifts,
        investmentFromInvestmentGains: formData4.inverstmentfrominverstmentgains,
        investmentFromOthers: formData4.inverstmentfromothers,
        inverstmentFromOthersInfo: formData4.inverstmentfromothersinfo,
        last_level: 5,
      };
      const result: any = await dispatch(updateEntity(updateBody));

      setShowDiv((prev: number) => prev + 1);
      scrollToTop();
      if (totalValueError === 'Total of the values must meet 100% (with other% if entered)' || totalValueError === 'Total of the values must meet 100% (without other% if not entered)') {

        setShowSourceModal(true);
      }

    } else if (_showDiv === 5) {
      const updateBody = {
        accountId,
        totalNetWorth: Number(FormDataNetworth.totalnetworth),
        liquidNetWorth: Number(FormDataNetworth.liquidnetworth),
        totalNetIncome: Number(FormDataNetworth.totalnetincome),
        last_level: 6,
      };
      dispatch(updateEntity(updateBody));
      setShowDiv((prev: number) => prev + 1);
      scrollToTop();
    }

    else if (_showDiv === 6) {
      const updateBody = {
        accountId,
        isPep: FormDataFamily.isPep,
        isFamilyPep: FormDataFamily.isFamilyPep,
        familymemberpepdetails: FormDataFamily.familymemberpepdetails,
        last_level: 7,
      };
      dispatch(updateEntity(updateBody));
      setShowDiv((prev: number) => prev + 1);
      scrollToTop();
    } else if (_showDiv === 7) {
      const updateBody = {
        accountId,
        employedfinancialservicefirm: FormDataFamliyFinance.employedfinancialservicefirm,
        whoemployedfinancialservicefirm: FormDataFamliyFinance.whoemployedfinancialservicefirm,
        familymemberrealtionship: FormDataFamliyFinance.familymemberrealtionship,
        complianceofficername: FormDataFamliyFinance.complianceofficername,
        complianceofficercontactnumber: FormDataFamliyFinance.complianceofficercontactnumber,
        complianceofficeremail: FormDataFamliyFinance.complianceofficeremail,
        last_level: 8,
      };
      dispatch(updateEntity(updateBody));
      setShowDiv((prev: number) => prev + 1);
      scrollToTop();
    }
    else if (_showDiv === 8) {
      const updateBody = {
        accountId: mainAccountsEntity.accountId || location.state?.account_id,
        last_level: 100,
      };
      const response: any = await dispatch(updateEntity(updateBody));
      setAccountType(response?.payload?.data?.accountHolderType);

      if (pendingTaskAccountId?.[0]?.need_more_info_description) {
        navigate("/dashboard")
      } else {
        // setShowDiv((prev: number) => prev + 91);
        setShowDiv(100);
      }

      setOpen7(true);
      scrollToTop();

    } else if (_showDiv === 100) {
      if (docConfirmation) {
        const updateBody = {
          accountId: accountId || location?.state?.account_id,
          last_level: 101,
        };
        const response: any = await dispatch(updateEntity(updateBody));
        // handleSosSignatureUpdate('sos');
        setAccountType(response?.payload?.data?.accountHolderType);
        setOpen3(true);
        // setShowDiv((prev: number) => prev + 1);
        setShowDiv(101);


        scrollToTop();
      }

    } else if (_showDiv === 101) {
      const updateBody = {
        accountId: accountId || location.state?.account_id,
        last_level: 102,
      };
      const response: any = await dispatch(updateEntity(updateBody));
      setAccountType(response?.payload?.data?.accountHolderType);
      setOpen4(true);
      // setShowDiv((prev: number) => prev + 1);
      if (customerId !== mainAccountsEntity?.customerId) {
        setShowDiv(103)
      } else {
        setShowDiv(102);
      }

      scrollToTop();
    } else if (_showDiv === 102) {
      const updateBody = {
        accountId: accountId || location.state?.account_id,
        last_level: 103,
      };
      const response: any = await dispatch(updateEntity(updateBody));
      setAccountType(response?.payload?.data?.accountHolderType);
      // setShowDiv((prev: number) => prev + 1);
      // if (AccountType === 'Individual') {
      if (customerId === mainAccountsEntity?.customerId) {
        setShowDiv(104)
        // }
      } else {
        setShowDiv(103);
      }
      setUpdateSuccess(true);
      scrollToTop();
    }
    else if (_showDiv === 103) {
      const updateBody = {
        accountId: accountId || location.state?.account_id,
        last_level: 104,
      };
      const response: any = await dispatch(updateEntity(updateBody));
      setAccountType(response?.payload?.data?.accountHolderType);
      // setShowDiv((prev: number) => prev + 1);
      setShowDiv(104);
      setUpdateSuccess(true);
      scrollToTop();
    }
    else if (_showDiv === 104) {
      const updateBody = {
        accountId: accountId || location.state?.account_id,
        last_level: 105,
        ib_customer_agreement: interactive?.ib_customer_agreement,
        ib_client_financial_advisor: interactive?.ib_client_financial_advisor,
        elt: interactive?.elt,
        ibsd_notification: interactive?.ibsd_notification,
        lla: interactive?.lla,
      };
      const response: any = await dispatch(updateEntity(updateBody));
      setAccountType(response?.payload?.data?.accountHolderType);
      if (AccountType === 'Individual' || customerId !== mainAccountsEntity?.customerId) {
        setOpen(true);
      } else {
        setOpen5(true);
      }
      setUpdateSuccess(true);
      dispatch(reset());
      scrollToTop();
    }
  };

  const showDiv2Function = (lcl_showDiv: number) => {

    // const _errors: IValidationErrors = {};

    // if (!investmentAmount) {
    //   _errors.investmentAmount = 'This field is mandatory';
    // }
    // if (investError) {
    //   _errors.investError = 'Minimum value should be €50,000';
    // }
    // if (!FormData2.addressLine1) {
    //   _errors.addressLine1 = 'This field is mandatory';
    // }
    // if (!FormData2.townorcity) {
    //   _errors.townorcity = 'This field is mandatory';
    // }
    // if (!FormData2.postcode) {
    //   _errors.postcode = 'This field is mandatory';
    // }
    // if (!FormData2.ppsnumber || ppsError) {
    //   _errors.ppsnumber = 'This field is mandatory';
    // }
    // if (!uploadedFile) {
    //   _errors.uploadedFile = 'This field is mandatory';
    // }
    // if (!FormData2.passport_number) {
    //   _errors.passport_number = 'This field is mandatory';
    // }
    // if (!uploadedPassport) {
    //   _errors.uploadedPassport = 'This field is mandatory';
    // }
    // if (!FormData2.passport_expiry_date) {
    //   _errors.passport_expiry_date = 'This field is mandatory';
    // }
    // if (FormData2.istaxresident === true && !FormData2.othertaxresidence) {
    //   _errors.othertaxresidence = 'This field is mandatory';
    // }

    // if (employment_status === "EMPLOYED") {
    //   if (!FormData2.occupation) {
    //     _errors.occupation = 'This field is mandatory';
    //   }
    //   if (!FormData2.employername) {
    //     _errors.employername = 'This field is mandatory';
    //   }
    //   if (!FormData2.employerbusiness) {
    //     _errors.employerbusiness = 'This field is mandatory';
    //   }
    //   // if (!FormData2.employerAddress?.country) {
    //   //   _errors.country = 'This field is mandatory';
    //   // }
    //   // if (!FormData2.employerAddress?.state) {
    //   //   _errors.state = 'This field is mandatory';
    //   // }
    //   if (!FormData2.employerAddress?.city) {
    //     _errors.city = 'This field is mandatory';
    //   }
    //   if (!FormData2.employerAddress?.postal_code) {
    //     _errors.postal_code = 'This field is mandatory';
    //   }
    //   if (!FormData2.employerAddress?.street) {
    //     _errors.street = 'This field is mandatory';
    //   }
    //   // if (!FormData2.isapubliclylistedcompany) {
    //   //   _errors.isapubliclylistedcompany = 'This field is mandatory';
    //   // }
    // }
    // if (Object.keys(_errors).length > 0) {
    //   setValidationErrors(_errors);


    //   setToastMessage((prev) => 'Please fill all the mandatory fields');
    //   setToastType('error');
    //   setToastVisible(true);
    //   return;
    // }
    // setValidationErrors({});

    const updateBody = {
      accountId: mainAccountsEntity.accountId || pendingTaskAccountId?.[0]?.accountId,
      accountHolderTitle: FormData2.nametitle,
      accountHolderEmail: email,
      accountHolderType: AccountType,
      accountHolderSurname: lastName,
      accountHolderName: name,
      middle_name,
      investmentAmount,
      accountHolderMaritalStatus: FormData2.maritalstatus,
      accountHolderDependents: FormData2.dependents,
      accountHolderMobileNumber: phone,
      addressLine1: FormData2.addressLine1,
      addressLine2: FormData2.addressLine2,
      townOrCity: FormData2.townorcity,
      country: FormData2.country,
      postcode: FormData2.postcode,
      ppsNumber: FormData2.ppsnumber,
      istaxresident: FormData2.istaxresident,
      othertaxresidence: FormData2.othertaxresidence,
      employment_status,
      occupation: FormData2.occupation,
      employerName: FormData2.employername,
      employerBusiness: FormData2.employerbusiness,
      employerAddress: FormData2.employerAddress,
      isapubliclylistedcompany: FormData2.isapubliclylistedcompany,
      ppsNumberDoc: uploadedFile,
      passportDoc: uploadedPassport,
      passport_number: FormData2.passport_number,
      passport_expiry_date: FormData2.passport_expiry_date,
      issued_country: FormData2.issued_country,
      state_or_province: FormData2.state_or_province,
      last_level: 3,
      mailing_address: {
        country: newcitizen.country,
        addressLineOne: newcitizen.addressLineOne,
        addressLineTwo: newcitizen.addressLineTwo,
        city: newcitizen.city,
        stateOrProvince: FormData2.state_or_province,
        zipOrPostalCode: FormData2.postcode,
      },
      birth_info: {
        countryOfBirth: newcitizen?.birth_info?.countryOfBirth,
      },
    };

    dispatch(updateEntity(updateBody));
    if (lcl_showDiv === 2) {
      if (AccountType === 'Individual') {
        setShowDiv(4);
        scrollToTop();
        return;
      } else {
        setShowDiv((prev: number) => prev + 1);
        scrollToTop();
      }
    }
  }

  const showDiv3Function = async () => {
    const _errors: IValidationErrors = {};

    if (!secondaryform.jointaddressline1) {
      _errors.jointaddressline1 = 'This field is mandatory';
    }
    if (!secondaryform.jointfirstname) {
      _errors.jointfirstname = 'This field is mandatory';
    }
    if (!secondaryform.jointsurname) {
      _errors.jointsurname = 'This field is mandatory';
    }
    if (!secondaryform.joint_username) {
      _errors.joint_username = 'This field is mandatory';
    }
    if ((!secondaryform.jointmobilenumber || secondaryform.jointmobilenumber === '+353') || secondaryform.jointmobilenumber.length !== 13) {
      _errors.jointmobilenumber = 'Please enter a valid mobile number starting with +353 and up to 9 digits.';
    }




    if (!secondaryform.jointppsnumber) {
      _errors.jointppsnumber = 'This field is mandatory';
    }
    if (!secondaryform.dependendPassportNumber) {
      _errors.dependendPassportNumber = 'This field is mandatory';
    }
    if (!joint_pps_doc) {
      _errors.joint_pps_doc = 'This field is mandatory';
    }
    if (!joint_passport_doc) {
      _errors.joint_passport_doc = 'This field is mandatory';
    }
    if (!secondaryform.jointtownorcity) {
      _errors.jointtownorcity = 'This field is mandatory';
    }
    if (!secondaryform.jointpostcode) {
      _errors.jointpostcode = 'This field is mandatory';
    }
    if (!joint_pps_doc) {
      _errors.joint_pps_doc = 'This field is mandatory';
    }
    if (!joint_passport_doc) {
      _errors.joint_passport_doc = 'This field is mandatory';
    }
    if (!secondaryform.jointpassport_expiry_date) {
      _errors.jointpassport_expiry_date = 'This field is mandatory';
    }
    if (jointemploymentstatus === "EMPLOYED") {
      if (!secondaryform.jointoccupation) {
        _errors.jointoccupation = 'This field is mandatory';
      }
      if (!secondaryform.jointemployername) {
        _errors.jointemployername = 'This field is mandatory';
      }
      if (secondaryform.jointistaxresident === true && !secondaryform.jointothertaxresidence) {
        _errors.jointothertaxresidence = 'This field is mandatory';
      }
      if (!secondaryform.jointemployerbusiness) {
        _errors.jointemployerbusiness = 'This field is mandatory';
      }
      if (!secondaryform.jointemployeraddress?.city) {
        _errors.city = 'This field is mandatory';
      }
      if (!secondaryform.jointemployeraddress?.postal_code) {
        _errors.postal_code = 'This field is mandatory';
      }
      if (!secondaryform.jointemployeraddress?.street) {
        _errors.street = 'This field is mandatory';
      }
    }
    if (Object.keys(_errors).length > 0) {
      setsecondValidationErrors(_errors);
      // toast.error('Please fill all the mandatory fields', {
      //   position: toast.POSITION.TOP_CENTER,
      //   className: 'custom-toast'
      // });
      setToastMessage((prev) => 'Please fill all the mandatory fields');
      setToastType('error');
      setToastVisible(true);

      return;
    }
    setsecondValidationErrors({});
    const body: any = {
      accountId: mainAccountsEntity.accountId,
      is_joint_account: true,
      joint_details: {
        jointnametitle: secondaryform.jointnametitle,
        jointfirstname: secondaryform.jointfirstname,
        jointsurname: secondaryform.jointsurname,
        jointemail: secondaryform.jointemail,
        jointdob: secondaryform.jointdob,
        jointmaritalstatus: secondaryform.jointmaritalstatus,
        jointdependents: secondaryform.jointdependents,
        joint_username: secondaryform.joint_username,
        jointmobilenumber: secondaryform.jointmobilenumber,
        jointaddressline1: secondaryform.jointaddressline1,
        jointaddressline2: secondaryform.jointaddressline2,
        jointtownorcity: secondaryform.jointtownorcity,
        jointcountry: secondaryform.jointcountry,
        jointpostcode: secondaryform.jointpostcode,
        jointppsnumber: secondaryform.jointppsnumber,
        jointistaxresident: secondaryform.jointistaxresident,
        jointothertaxresidence: secondaryform.jointothertaxresidence,
        dependendPassportNumber: secondaryform.dependendPassportNumber,
        jointemploymentstatus,
        jointoccupation: secondaryform.jointoccupation,
        jointemployername: secondaryform.jointemployername,
        jointemployerbusiness: secondaryform.jointemployerbusiness,
        jointemployeraddress: secondaryform.jointemployeraddress,
        jointpassport_expiry_date: secondaryform.jointpassport_expiry_date,
        jointisapubliclylistedcompany: secondaryform.jointisapubliclylistedcompany,
        jointstateorprovince: secondaryform.jointstateorprovince,
        jointcountryofbirth: secondaryform.jointcountryofbirth,
        jointissuedcountry: secondaryform.jointissuedcountry,
        joint_pps_doc,
        joint_passport_doc,
        jointownership: 50,
        last_level: 4,
      },
    };

    const result: any = await dispatch(updateEntity(body));
    if (result?.payload?.data) {
      setAccountId(result?.payload?.data?.accountId);
      setCountry(result?.payload?.data?.country);
      setShowDiv((prev: number) => prev + 1);
      setOpen6(true);
      setToastMessage((prev) => 'Please check your email for a secure code');
      setToastType('success');
      setToastVisible(true);
      scrollToTop();
    }
  }

  const handlePreviousStep = (_showDiv: number) => {

    setShowDiv((prev: number) => prev - 1);
    if (mainAccountsEntity?.accountHolderType === 'Individual') {
      if (_showDiv === 5) {
        setShowDiv((prev: number) => prev - 1);
        scrollToTop();
        return;
      }
    }
    if (mainAccountsEntity?.accountHolderType === 'Joint') {
      if (_showDiv === 5) {
        setShowDiv(4);
        scrollToTop();
        return;
      }
    }

    if (_showDiv === 100) {
      setShowDiv((prev: number) => prev - 89);
    }
    const target1 = document.querySelector('#topSectionHolder');
    if (target1) {
      target1.scrollIntoView({ behavior: 'smooth' });
    }

    if (_showDiv === 104) {
      if (customerId === mainAccountsEntity?.customerId) {
        setShowDiv((prev: number) => prev - 1);
      }
    }
    const target2 = document.querySelector('#topSectionHolder');
    if (target2) {
      target2.scrollIntoView({ behavior: 'smooth' });
    }

    if (_showDiv === 103) {
      if (customerId !== mainAccountsEntity?.customerId) {
        setShowDiv((prev: number) => prev - 1);
      }
    }
    scrollToTop();

  };

  const saveSosWithServer = (signaturetype, signatureValue) => {

    let accountId = accountsEntity?.toString() || '';
    if (!accountId && accountId === '') {
      if (signatureData?.hasOwnProperty('statement_of_suitability') && signatureData?.statement_of_suitability?.hasOwnProperty('account_id')) {
        accountId = signatureData?.statement_of_suitability?.account_id;
      } else {
        return;
      }
    }
    const payload = {
      accountId,
      signaturetype,
      signed_by: sosSecondarySign || sosPrimary_sign,
      signed_date: new Date().toISOString().split('T')[0],
    };

    try {
      dispatch(signature(payload)).then((response: any) => {
        if (response.error) {
          // Handle API error response
          const errorDetail = response?.payload?.detail || '';
          if (response.payload?.status === 400 && errorDetail === 'Already signed') {

            setToastMessage('Save failed: You have already signed this document.');
            setToastType('error');
            setToastVisible(true);
            return;
          }
        } else {
          const updatedSignature = response.payload;

          if (signaturetype === 'sos') {
            if (signatureValue === sosPrimary_sign) {
              setsosPrimarySign(updatedSignature?.primary_sign || sosPrimary_sign);
            } else {
              setsosSecondarySign(updatedSignature?.joint_sign || sosSecondarySign);
            }
          }
          setSaveToast(true);
        }
       
      });
    } catch (error) { /* empty */ }
  };

  const saveMpsdWithServer = (signaturetype, signatureValue) => {
    if (!accountsEntity) {
      return;
    }

    const payload = {
      accountId: accountsEntity,
      signaturetype,
      signed_by: smart_reserve_SecondarySign || smart_reserve_primary_sign,
      signed_date: new Date().toISOString().split('T')[0],
    };

    try {
      dispatch(signature(payload)).then((response: any) => {
        if (response.error) {
          // Handle API error response
          const errorDetail = response?.payload?.detail || '';
          if (response.payload?.status === 400 && errorDetail === 'Already signed') {
            setToastMessage('Save failed: You have already signed this document.');
            setToastType('error');
            setToastVisible(true);

          }
        } else {
          const updatedSignature = response.payload;

          if (signaturetype === 'smart_reserve') {
            if (signatureValue === smart_reserve_primary_sign) {
              setsmart_reserve_primary_sign(updatedSignature?.primary_sign || smart_reserve_primary_sign);
            } else {
              setsmart_reserve_SecondarySign(updatedSignature?.joint_sign || smart_reserve_SecondarySign);
            }
          }
          setSaveToast(true);
        }
      });
      // setToastMessage('Save Successfully');
      // setToastType('success');
      // setToastVisible(true);
    }
    catch (error) { /* empty */ }
  };

  const saveFormdocWithServer = (signaturetype, signatureValue) => {
    if (!accountsEntity) {
      return;
    }

    const formPayload = {
      signed_by: w8benPrimary_sign,
      signed_date: new Date().toISOString().split('T')[0], // Using the current date
      mailing_address: {
        street: mailing_address.street,
        city: mailing_address.city,
        state: mailing_address.state,
        postal_code: mailing_address.postal_code,
        country: mailing_address.country,
      },
      ssn: differentData.ssn,
      ftn: true,
      reference_number: differentData.reference_number,
      beneficial_owner_residence: differentData.beneficial_owner_residence,
      article_and_paragraph: differentData.article_and_paragraph,
      rate_of_with_holding: differentData.rate_of_with_holding,
      type_of_income: differentData.type_of_income,
      additional_condition: differentData.additional_condition,
      eligibility_explanation: differentData.eligibility_explanation,
    };

    try {
      dispatch(submitW8BenForm({ accountId: accountsEntity, payload: formPayload })).then((response: any) => {
        if (response.type === 'accounts/submit_w8ben_form/rejected') {
          // Handle rejected response
          const errorMessage = response.payload || 'Failed to submit W8Ben form';

          setToastMessage(`Save failed: You have already signed this document.`);
          setToastType('error');
          setToastVisible(true);
          return;
        } else {
          const updatedSignature = response.payload;

          if (signaturetype === 'w8ben') {
            if (signatureValue === w8benPrimary_sign) {
              setw8benPrimary_sign(updatedSignature?.primary_sign || w8benPrimary_sign);
            }
          }
          setSaveToast(true);
        }
      });
    } catch (error) {
      setToastMessage('Unexpected error occurred. Please try again.');
      setToastType('error');
      setToastVisible(true);
    }
  };
  const saveIbkrWithServer = (signaturetype, signatureValue) => {
    let accountId = accountsEntity?.toString() || '';
    if (!accountId) {
      if (
        signatureData?.hasOwnProperty('statement_of_suitability') &&
        signatureData?.statement_of_suitability?.hasOwnProperty('account_id')
      ) {
        accountId = signatureData?.statement_of_suitability?.account_id;
      } else {
        return;
      }
    }

    const payload = {
      accountId,
      signaturetype,
      signed_by: ibkrSecondarySign || ibkrPrimary_sign,
      signed_date: new Date().toISOString().split('T')[0],
    };

    try {
      dispatch(signature(payload)).then((response: any) => {
        if (response.error) {
          // Handle API error response
          const errorDetail = response?.payload?.detail || '';
          if (response.payload?.status === 400 && errorDetail === 'Already signed') {
            setToastMessage('Save failed: You have already signed this document.');
            setToastType('error');
            setToastVisible(true);
            return;
          }
        } else {

          const updatedSignature = response.payload;

          if (signaturetype === 'ibkr') {
            if (signatureValue === ibkrPrimary_sign) {
              setibkrPrimarySign(updatedSignature?.primary_sign || ibkrPrimary_sign);
            } else {
              setibkrSecondarySign(updatedSignature?.joint_sign || ibkrSecondarySign);
            }
          }
          setSaveToast(true);
        }
      });
    } catch (error) {
      //empty error message//
    }
  };


  const validateTotalPercentage = () => {
    const {
      invertmentfrompropertygains,
      inverstmentfromgifts,
      inverstmentfrominverstmentgains,
      inverstmentfromothers,
      inverstmentfromemploymentincome,
    } = formData4;

    const totalPercentage =
      Number(invertmentfrompropertygains || '0') +
      Number(inverstmentfromgifts || '0') +
      Number(inverstmentfrominverstmentgains || '0') +
      Number(inverstmentfromemploymentincome || '0') +
      (inverstmentfromothers ? Number(inverstmentfromothers) : 0);

    if (inverstmentfromothers) {
      if (totalPercentage !== 100) {
        setTotalValueError('Total of the values must meet 100% (with other% if entered)');
      } else {
        setTotalValueError('');
      }
    } else {
      const totalWithoutOthers =
        Number(invertmentfrompropertygains || '0') +
        Number(inverstmentfromgifts || '0') +
        Number(inverstmentfrominverstmentgains || '0') +
        Number(inverstmentfromemploymentincome || '0');

      if (totalWithoutOthers !== 100) {
        setTotalValueError('Total of the values must meet 100% (without other% if not entered)');
      } else {
        setTotalValueError('');
      }
    }
  };

  useEffect(() => {
    validateTotalPercentage();
  }, [formData4]);

  const handleCheckboxChangeThree = e => {
    setDocConfirmation3({ ...docConfirmation3, statement3: e.target.checked });
  };
  //last  form

  // const handleCheckboxibustomerAgreement = e => {
  //   setInteractive({ ...interactive, ib_customer_agreement: e.target.checked });
  // };

  // const handleCheckboxibclientFinancialAdvisor = e => {
  //   setInteractive({ ...interactive, ib_client_financial_advisor: e.target.checked });
  // };
  // const handleCheckboxelt = e => {
  //   setInteractive({ ...interactive, elt: e.target.checked });
  // };
  // const handleCheckboxibsdNotification = e => {
  //   setInteractive({ ...interactive, ibsd_notification: e.target.checked });
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name: checkedName, checked } = e.target;
    setInteractive(prevState => ({
      ...prevState,
      [checkedName]: checked,
    }));
    dispatch(updateEntityProperty({ [checkedName]: checked }));

    const updatedEntity = { ...mainAccountsEntity, [checkedName]: checked };
    dispatch(updateEntity(updatedEntity));
  };


  const handleCheckboxLLA = e => {
    setInteractive({ ...interactive, lla: e.target.checked });
  };

  //================================================================//
  // Latest Changes / Bug Fixes from InitialRequirement component layer
  //================================================================//

  const initialRequirementRef = useRef<{ syncInitialRequirementWithServer, saveInitialRequirementWithServer: () => Promise<any> }>(null);
  const [initialRequirementButton, setInitialRequirementButton] = useState<boolean>(false);

  const handleInitialRequirementSubmit = async (): Promise<void> => {
    if (initialRequirementRef.current) {
      const response = await initialRequirementRef.current.syncInitialRequirementWithServer();

      if (response) {
        setShowDiv((prev: number) => prev + 1);
        scrollToTop();
      }
    }
  };

  const handleInitialRequirementButtonSave = async (): Promise<void> => {
    if (initialRequirementRef.current) {
      const response = await initialRequirementRef.current.saveInitialRequirementWithServer();
      // setToastMessage((prev) => 'Save Successfully');
      // setToastType('success');
      // setToastVisible(true);
      setSaveToast(true);
    }
  }
  //================================================================//
  // Latest Changes / Bug Fixes from PrimaryApplication component layer
  //================================================================//
  const primaryDetailsRef = useRef<{ syncPrimaryDetailsWithServer, savePrimaryDetailsWithServer: () => Promise<any> }>(null);
  const [primaryDetailsButton, setPrimaryDetailsButton] = useState<boolean>(false);

  const handlePrimaryDetailsButtonSubmit = async (): Promise<void> => {
    if (primaryDetailsRef.current) {
      const response = await primaryDetailsRef.current.syncPrimaryDetailsWithServer();

      if (response) {
        setShowDiv((prev: number) => prev + 1);
        scrollToTop();
      }

    }
  };

  const handlePrimaryDetailsButtonSave = async (): Promise<void> => {
    if (primaryDetailsRef.current) {
      const response = await primaryDetailsRef.current.savePrimaryDetailsWithServer();
      // setToastMessage((prev) => 'Save Successfully');
      // setToastType('success');
      // setToastVisible(true);
      setSaveToast(true);
    }
  }

  //================================================================//
  // Latest Changes / Bug Fixes from PrimaryAddressDetails component layer
  //================================================================//
  const primaryAddressDetailsRef = useRef<{ syncPrimaryAddressDetailsWithServer, savePrimaryAddressDetailsWithServer: () => Promise<any> }>(null);
  const [primaryAddressDetailsButton, setPrimaryAddressDetailsButton] = useState<boolean>(false);

  const handlePrimaryAddressDetailsButtonSubmit = async (): Promise<void> => {
    if (primaryAddressDetailsRef.current) {
      const response = await primaryAddressDetailsRef.current.syncPrimaryAddressDetailsWithServer();

      if (response !== null) {
        if (showDiv === 3) {
          if (mainAccountsEntity?.accountHolderType === 'Individual') {
            setShowDiv(5);
          } else {
            setShowDiv((prev: number) => prev + 1);
          }
          scrollToTop();
        }
      }

    }
  };

  const handlePrimaryAddressDetailsButtonSave = async (): Promise<void> => {
    if (primaryAddressDetailsRef.current) {
      const response = await primaryAddressDetailsRef.current.savePrimaryAddressDetailsWithServer();
      // setToastMessage((prev) => 'Save Successfully');
      // setToastType('success');
      // setToastVisible(true);
      setSaveToast(true);
    }
  }

  //Secondary
  const secondaryDetailsRef = useRef<{ syncSecondaryDetailsWithServer: () => Promise<any> }>(null);
  const [secondaryDetailsButton, setSecondaryDetailsButton] = useState<boolean>(false);

  const handleSecondaryDetailsButtonSubmit = async (): Promise<void> => {
    if (secondaryDetailsRef.current) {
      try {
        const response = await secondaryDetailsRef.current.syncSecondaryDetailsWithServer();


        if (response?.payload?.status === 400) {

          setToastMessage((prev) => "Invalid mobile number");
          setToastType('error');
          setToastVisible(true);

        }

        if (response.payload.data.id) {

          setShowDiv((prev: number) => prev + 1);
          setOpen6(true);
          // setToastMessage('Please check your email for a secure code');
          // setToastType('success');
          // setToastVisible(true);
          scrollToTop();
        } else {
          // Handle other response statuses if necessary
        }
      } catch (error) {
        // Handle error if necessary
        console.error('Error:', error);
      }
    }
  };


  //================================================================//
  // Latest Changes / Bug Fixes from AntiMoney component layer
  //================================================================//


  const antiMoneyRef = useRef<{ syncAntiMoneyWithServer, saveAntiMoneyWithServer: () => Promise<any> }>(null);
  const [antiMoneyButton, setAntiMoneyButton] = useState<boolean>(false);

  const handleAntiMoneySubmit = async (): Promise<void> => {
    if (antiMoneyRef.current) {
      const response = await antiMoneyRef.current.syncAntiMoneyWithServer();

      if (response) {
        setShowDiv((prev: number) => prev + 1);
        scrollToTop();
      }
    }
  };

  const handleAntiMoneyButtonSave = async (): Promise<void> => {
    if (antiMoneyRef.current) {
      const response = await antiMoneyRef.current.saveAntiMoneyWithServer();
      // setToastMessage((prev) => 'Save Successfully');
      // setToastType('success');
      // setToastVisible(true); 
      setSaveToast(true);
    }
  }

  //================================================================//
  // Latest Changes / Bug Fixes from NetWorthAnnual component layer
  //================================================================//


  const netWorthAnnualRef = useRef<{ syncNetWorthAnnualWithServer, saveNetWorthAnnualWithServer: () => Promise<any> }>(null);
  const [netWorthAnnualButton, setNetWorthAnnualButton] = useState<boolean>(false);

  const handleNetWorthAnnualSubmit = async (): Promise<void> => {
    if (netWorthAnnualRef.current) {
      const response = await netWorthAnnualRef.current.syncNetWorthAnnualWithServer();

      if (response) {
        setShowDiv((prev: number) => prev + 1);
        scrollToTop();
      }
    }
  };
  const handleNetWorthAnnualButtonSave = async (): Promise<void> => {
    if (netWorthAnnualRef.current) {
      const response = await netWorthAnnualRef.current.saveNetWorthAnnualWithServer();
      // setToastMessage((prev) => 'Save Successfully');
      // setToastType('success');
      // setToastVisible(true);
      setSaveToast(true);
    }
  }
  const [secondaryId, setSecondaryId] = useState()

  //================================================================//
  // Latest Changes / Bug Fixes from PEP component layer
  //================================================================//


  const pepRef = useRef<{ syncPepWithServer, savePepWithServer: () => Promise<any> }>(null);

  const handlePEPSubmit = async (): Promise<void> => {
    if (pepRef.current) {
      const response = await pepRef.current.syncPepWithServer();

      if (response) {
        setShowDiv((prev: number) => prev + 1);
        scrollToTop();
      }
    }
  };
  const handlePepButtonSave = async (): Promise<void> => {
    if (pepRef.current) {
      const response = await pepRef.current.savePepWithServer();
      // setToastMessage((prev) => 'Save Successfully');
      // setToastType('success');
      // setToastVisible(true);
      setSaveToast(true);
    }
  }

  //================================================================//
  // Latest Changes / Bug Fixes from FinancialServices component layer
  //================================================================//


  const financialServicesRef = useRef<{ syncFinancialServicesWithServer, saveFinancialServiceWithServer: () => Promise<any> }>(null);
  const [financialServicesButton, setFinancialServicesButton] = useState<boolean>(false);


  const handleFinancialServicesSubmit = async (): Promise<void> => {
    if (financialServicesRef.current) {
      const response = await financialServicesRef.current.syncFinancialServicesWithServer();

      if (response) {
        setShowDiv((prev: number) => prev + 1);
        scrollToTop();
      }
    }
  };

  const handleFinancialServicesButtonSave = async (): Promise<void> => {
    if (financialServicesRef.current) {
      const response = await financialServicesRef.current.saveFinancialServiceWithServer();
      // setToastMessage((prev) => 'Save Successfully');
      // setToastType('success');
      // setToastVisible(true);
      setSaveToast(true);
    }
  }

  //================================================================//
  // Latest Changes / Bug Fixes from PrimaryDocumentDetails component layer
  //================================================================//
  const primaryDocumentDetailsRef = useRef<{ syncPrimaryDocumentDetailsWithServer, savePrimaryDocumentDetailsWithServer: () => Promise<any> }>(null);
  const [primaryDocumentDetailsButton, setPrimaryDocumentDetailsButton] = useState<boolean>(false);

  const handlePrimaryDocumentDetailsButtonSubmit = async (): Promise<void> => {
    if (primaryDocumentDetailsRef.current) {
      const response = await primaryDocumentDetailsRef.current.syncPrimaryDocumentDetailsWithServer();

      if (response) {
        setShowDiv((prev: number) => prev + 1);
        scrollToTop();
      }

    }
  };

  const handlePrimaryDocumentDetailsButtonSave = async (): Promise<void> => {
    if (primaryDocumentDetailsRef.current) {
      const response = await primaryDocumentDetailsRef.current.savePrimaryDocumentDetailsWithServer();
      // setToastMessage((prev) => 'Save Successfully');
      // setToastType('success');
      // setToastVisible(true);
      setSaveToast(true);
    }
  }

  //================================================================//
  // Latest Changes / Bug Fixes from AccountSummary component layer
  //================================================================//


  const accountSummaryRef = useRef<{ syncAccountSummaryWithServer: () => Promise<any> }>(null);
  const [accountSummaryButton, setAccountSummaryButton] = useState<boolean>(false);

  const handleAccountSummarySubmit = async (): Promise<void> => {
    if (accountSummaryRef.current) {
      const response = await accountSummaryRef.current.syncAccountSummaryWithServer();

      if (response) {
        setShowDiv(100);
        scrollToTop();
      }
      setOpen7(true);
    }
  };

  const totalSteps = 14;

  const calculateProgress = (currentStep: number): number => {
    if (isNaN(currentStep) || currentStep < 1) {
      return 0;
    }
    if (currentStep >= totalSteps) {
      return 100;
    }
    return ((currentStep - 1) / totalSteps) * 100;
  };


  useEffect(() => {
    setShowdivprogress(calculateProgress(PROGRESS_PERCENTAGE[showDiv]));
    setShowdivper(Math.round(calculateProgress(PROGRESS_PERCENTAGE[showDiv])));
  }, [showDiv]);

  return (
    <div>
      <CommonToast
        visible={toastVisible}
        onClose={() => setToastVisible(false)}
        title={toastType === 'success' ? 'Success' : 'Error'}
        description={toastMessage}
        type={toastType}
        isAutoClose={true}
      />
      <Row>
        <Col md="12" className="custom-bg-account pb-5">
          <div className="d-md-flex justify-content-md-center pb-3">
            <Form className="form-account-custom bg-white">
              <Row className="text-center">
                <Col md="12" className="pt-3 d-flex align-items-center justify-content-between">
                  <Progress className="Progress-account-custom flex-grow-1" value={showdivprogress} max="100" />
                  <span className="progress-label ms-2">{Math.round(showdivprogress)}%</span>
                </Col>
              </Row>
              <div>
                {/* {showDiv === 0 && (
                  <div>
                    <Welcome />
                    <Row>
                      <div className="col-12 pt-5">
                        <Button
                          className="button-account-custom-submit"
                          onClick={() => {
                            setShowDiv((prev: number) => prev + 1);
                            scrollToTop();
                          }}
                        >
                          Next &nbsp;<FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                      </div>
                    </Row>
                  </div>
                )} */}

                {showDiv === 1 && (
                  <div>
                    <InitialRequirementCheck ref={initialRequirementRef} onFormComplete={(status) => { setInitialRequirementButton(status) }} />
                    <Row>
                      <FormGroup>
                        <Col md="12" className="under-border-div mt-3 mb-3"></Col>
                      </FormGroup>
                    </Row>
                    <Row>
                      <div className="col-4">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                          <FontAwesomeIcon icon={faArrowLeft} />&nbsp;
                          Previous
                        </Button>
                      </div>
                      <div className="col-4">
                        <Button className="button-account-custom" onClick={() => { handleInitialRequirementButtonSave(); }}
                          disabled={!initialRequirementButton}
                        >
                          <FontAwesomeIcon icon={faSave} />&nbsp;
                          Save
                        </Button>
                      </div>
                      <div className='col-4'>
                        <Button
                          className="button-account-custom-submit"
                          onClick={() => {
                            // handleNextStep(showDiv);
                            handleInitialRequirementSubmit();
                          }}
                          disabled={!initialRequirementButton}
                        >
                          Next
                          &nbsp;<FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                      </div>
                    </Row>
                  </div>
                )}

                {showDiv === 2 && (
                  <div>
                    <Row>
                      <PrimaryApplication ref={primaryDetailsRef} onFormComplete={(status) => { setPrimaryDetailsButton(status) }}

                      />
                      <Row>
                        <FormGroup>
                          <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                        </FormGroup>
                      </Row>
                      <Row>
                        <div className="col-4">
                          <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                            <FontAwesomeIcon icon={faArrowLeft} />&nbsp;
                            Previous
                          </Button>
                        </div>
                        <div className="col-4">
                          <Button className="button-account-custom" onClick={() => { handlePrimaryDetailsButtonSave(); }}>
                            <FontAwesomeIcon icon={faSave} />&nbsp;
                            Save
                          </Button>
                        </div>
                        {mainAccountsEntity?.accountHolderType === "Individual" ? (
                          <div className='col-4'>
                            <Button className="button-account-custom-submit" onClick={() => { handlePrimaryDetailsButtonSubmit(); }}
                            >
                              Next
                              &nbsp;<FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                          </div>

                        ) : (
                          <div className='col-4'>
                            <Button className="button-account-custom-submit" onClick={() => { handlePrimaryDetailsButtonSubmit(); }}
                            >
                              Next
                              &nbsp;<FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                          </div>
                        )}
                      </Row>
                    </Row>
                  </div>
                )}

                {showDiv === 3 && (
                  <div>
                    <Row>
                      <PrimaryAddressDetails ref={primaryAddressDetailsRef} onFormComplete={(status) => { setPrimaryAddressDetailsButton(status) }}

                      />
                      <Row>

                        
                        <FormGroup>
                          <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                        </FormGroup>
                      </Row>
                      <Row>
                        <div className="col-4">
                          <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                            <FontAwesomeIcon icon={faArrowLeft} />&nbsp;
                            Previous
                          </Button>
                        </div>
                        <div className="col-4">
                          <Button className="button-account-custom" onClick={() => { handlePrimaryAddressDetailsButtonSave(); }}>
                            <FontAwesomeIcon icon={faSave} />&nbsp;
                            Save
                          </Button>
                        </div>
                        {mainAccountsEntity?.accountHolderType === "Individual" ? (
                          <div className='col-4'>
                            <Button className="button-account-custom-submit" onClick={() => { handlePrimaryAddressDetailsButtonSubmit(); }}
                            >
                              Next
                              &nbsp;<FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                          </div>

                        ) : (
                          <div className='col-4'>
                            <Button className="button-account-custom-submit" onClick={() => { handlePrimaryAddressDetailsButtonSubmit(); }}
                            >
                              Next
                              &nbsp;<FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                          </div>
                        )}
                      </Row>
                    </Row>
                  </div>
                )}

                {showDiv === 4 && (
                  <div>
                    <SecondaryAplicaion
                      ref={secondaryDetailsRef} onFormComplete={(status) => { setSecondaryDetailsButton(status) }}
                    />
                    <Row>
                      <FormGroup>
                        <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                      </FormGroup>
                    </Row>
                    <Row>
                      <div className="col-6">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                          <FontAwesomeIcon icon={faArrowLeft} />&nbsp;
                          Previous
                        </Button>
                      </div>

                      <div className='col-6'>
                        <Button
                          className="button-account-custom-submit"
                          id="continueButton"
                          onClick={() => { handleSecondaryDetailsButtonSubmit(); }}
                        // disabled={!secondaryDetailsButton}
                        >
                          Next
                          &nbsp;<FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                      </div>
                    </Row>
                  </div>
                )}

                {showDiv === 5 && (
                  <div>
                    <AntiMoney ref={antiMoneyRef} onFormComplete={(status) => { setAntiMoneyButton(status) }} />
                    <Row>
                      <FormGroup>
                        <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                      </FormGroup>
                    </Row>

                    <Row>
                      <div className="col-4">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                          <FontAwesomeIcon icon={faArrowLeft} />&nbsp;
                          Previous
                        </Button>
                      </div>
                      <div className="col-4">
                        <Button className="button-account-custom" onClick={() => { handleAntiMoneyButtonSave(); }}>
                          <FontAwesomeIcon icon={faSave} />&nbsp;
                          Save
                        </Button>
                      </div>
                      <div className="col-4">
                        <Button
                          className="button-account-custom-submit"
                          onClick={() => {
                            // handleUpdateNextStep(showDiv);
                            handleAntiMoneySubmit();
                          }}
                          disabled={!antiMoneyButton}
                        >
                          Next
                          &nbsp;<FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                      </div>
                    </Row>
                    <Modal show={showSourceModal} onHide={handleSourceCloseModal}>
                      <ModalHeader closeButton>
                        Validation Error
                      </ModalHeader>
                      <ModalBody>{totalValueError}</ModalBody>
                      <ModalFooter>
                        <Button variant="secondary" onClick={handleSourceCloseModal}>
                          Close
                        </Button>
                      </ModalFooter>
                    </Modal>



                  </div>
                )}

                {showDiv === 6 && (
                  <div>
                    <>
                      <NetWorthAnnualIncome ref={netWorthAnnualRef} onFormComplete={(status) => { setNetWorthAnnualButton(status) }} />

                      <Row className='pt-4'>
                        <div className="col-4">
                          <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                            <FontAwesomeIcon icon={faArrowLeft} />&nbsp;
                            Previous
                          </Button>
                        </div>
                        <div className="col-4">
                          <Button className="button-account-custom" onClick={() => { handleNetWorthAnnualButtonSave(); }}>
                            <FontAwesomeIcon icon={faSave} />&nbsp;
                            Save
                          </Button>
                        </div>
                        <div className='col-4'>
                          <Button
                            className="button-account-custom-submit"
                            onClick={() => {
                              // handleUpdateNextStep(showDiv);
                              handleNetWorthAnnualSubmit()
                            }}
                            disabled={!(netWorthAnnualButton)}
                          >
                            Next
                            &nbsp;<FontAwesomeIcon icon={faArrowRight} />
                          </Button>
                        </div>
                      </Row>
                    </>
                  </div>
                )}


                {showDiv === 7 && (
                  <div>
                    <Pep ref={pepRef} onFormComplete={(status) => { }} />
                    <Row>
                      <FormGroup>
                        <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                      </FormGroup>
                    </Row>
                    <Row>
                      <div className="col-4">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                          <FontAwesomeIcon icon={faArrowLeft} />&nbsp;
                          Previous
                        </Button>
                      </div>
                      <div className="col-4">
                        <Button className="button-account-custom" onClick={() => { handlePepButtonSave(); }}>
                          <FontAwesomeIcon icon={faSave} />&nbsp;
                          Save
                        </Button>
                      </div>
                      <div className='col-4'>
                        <Button
                          className="button-account-custom-submit"
                          onClick={() => {
                            // handleUpdateNextStep(showDiv);
                            handlePEPSubmit();
                          }}
                          disabled={
                            (mainAccountsEntity.isPep === false || mainAccountsEntity.isFamilyPep === false) &&
                            !mainAccountsEntity.familymemberpepdetails
                          }
                        >
                          Next
                          &nbsp;<FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                      </div>
                    </Row>
                  </div>
                )}

                {showDiv === 8 && (
                  <div>
                    <FinancialServices ref={financialServicesRef} onFormComplete={(status) => { setFinancialServicesButton(status) }} />
                    <Row>
                      <FormGroup>
                        <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                      </FormGroup>
                    </Row>
                    <Row>
                      <div className="col-4">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                          <FontAwesomeIcon icon={faArrowLeft} />&nbsp;
                          Previous
                        </Button>
                      </div>
                      <div className="col-4">
                        <Button className="button-account-custom" onClick={() => { handleFinancialServicesButtonSave(); }}>
                          <FontAwesomeIcon icon={faSave} />&nbsp;
                          Save
                        </Button>
                      </div>
                      <div className='col-4'>
                        <Button
                          className="button-account-custom-submit"
                          onClick={() => {
                            handleFinancialServicesSubmit();
                          }}

                        >
                          Next
                          &nbsp;<FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                      </div>
                    </Row>
                  </div>
                )}
                {showDiv === 9 && (
                  <div>
                    <Row>
                      <PrimaryDocumentDetails ref={primaryDocumentDetailsRef} onFormComplete={(status) => { setPrimaryDocumentDetailsButton(status) }}

                      />
                      <Row>
                        <FormGroup>
                          <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                        </FormGroup>
                      </Row>
                      <Row>
                        <div className="col-4">
                          <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                            <FontAwesomeIcon icon={faArrowLeft} />&nbsp;
                            Previous
                          </Button>
                        </div>
                        <div className="col-4">
                          <Button className="button-account-custom" onClick={() => { handlePrimaryDocumentDetailsButtonSave(); }}>
                            <FontAwesomeIcon icon={faSave} />&nbsp;
                            Save
                          </Button>
                        </div>
                        {mainAccountsEntity?.accountHolderType === "Individual" ? (
                          <div className='col-4'>
                            <Button className="button-account-custom-submit" onClick={() => { handlePrimaryDocumentDetailsButtonSubmit(); }}
                            >
                              Next
                              &nbsp;<FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                          </div>

                        ) : (
                          <div className='col-4'>
                            <Button className="button-account-custom-submit" onClick={() => { handlePrimaryDocumentDetailsButtonSubmit(); }}
                            >
                              Next
                              &nbsp;<FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                          </div>
                        )}
                      </Row>
                    </Row>
                  </div>
                )}

                {showDiv === 10 && (
                  <div>
                    <AccountSummary ref={accountSummaryRef} onFormComplete={(status) => { setAccountSummaryButton(status) }}
                    />
                    <Row>
                      <div className="col-6">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                          <FontAwesomeIcon icon={faArrowLeft} />&nbsp;
                          Previous
                        </Button>
                      </div>
                      <div className="col-6">

                        {pendingTaskAccountId?.[0]?.need_more_info_description ? (
                          <Button
                            className="button-account-custom-submit"
                            onClick={() => { handleAccountSummarySubmit() }}
                            disabled={isEditing}
                          >
                            Save
                          </Button>
                        ) : (
                          <Button
                            className="button-account-custom-submit"
                            onClick={() => { handleAccountSummarySubmit() }}
                            disabled={isEditing}
                          >
                            Continue
                            &nbsp;<FontAwesomeIcon icon={faArrowRight} />
                          </Button>
                        )}
                      </div>
                    </Row>
                  </div>
                )}
                {showDiv === 100 && (
                  <>
                    <SosDoc
                      FName={name}
                      LName={lastName}
                      invest={investmentAmount}
                      docConfirmation={docConfirmation}
                      setDocConfirmation={setDocConfirmation}
                      sosPrimary_sign={sosPrimary_sign}
                      sosSecondarySign={sosSecondarySign}
                      accountHolderType={AccountType}
                      setsosPrimarySign={setsosPrimarySign}
                      setsosSecondarySign={setsosSecondarySign}
                    />
                    <Row>
                      <FormGroup>
                        <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                      </FormGroup>
                    </Row>
                    <Row>
                      <div className="col-4">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)} disabled={customerId !== mainAccountsEntity?.customerId} >
                          <FontAwesomeIcon icon={faArrowLeft} />&nbsp;
                          Previous
                        </Button>
                      </div>
                      <div className="col-4">
                        <Button className="button-account-custom" onClick={() => { saveSosWithServer('sos', sosPrimary_sign) }}>
                          <FontAwesomeIcon icon={faSave} />&nbsp;
                          Save
                        </Button>
                      </div>
                      <div className='col-4'>
                        <Button
                          className="button-account-custom-submit"
                          id="continueButton"

                          onClick={() => {
                            handleSosSignatureUpdate('sos', sosPrimary_sign);
                          }}
                          // disabled={!(sosPrimary_sign)}
                          disabled={mainAccountsEntity.customerId === customerId ? !(sosPrimary_sign) : !(sosSecondarySign)}
                        >
                          Continue
                          &nbsp;<FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                      </div>
                    </Row>
                  </>

                )}

                {showDiv === 101 && (
                  <>
                    <SecondDoc
                      accountHolderType={AccountType}
                      lastName={lastName}
                      name={name}
                      secondaryform={secondaryform}
                      smart_reserve_primary_sign={smart_reserve_primary_sign}
                      setsmart_reserve_primary_sign={setsmart_reserve_primary_sign}
                      smart_reserve_SecondarySign={smart_reserve_SecondarySign}
                      setsmart_reserve_SecondarySign={setsmart_reserve_SecondarySign}
                    />

                    <Row>
                      <FormGroup>
                        <Col md="12" className="under-border-div mt-1 mb-1"></Col>
                      </FormGroup>
                    </Row>
                    <Row>
                      <div className="col-4">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                          <FontAwesomeIcon icon={faArrowLeft} />&nbsp;
                          Previous
                        </Button>
                      </div>
                      <div className="col-4">
                        <Button className="button-account-custom" onClick={() => { saveMpsdWithServer('smart_reserve', smart_reserve_primary_sign) }}>
                          <FontAwesomeIcon icon={faSave} />&nbsp;
                          Save
                        </Button>
                      </div>
                      <div className='col-4'>
                        <Button
                          className="button-account-custom-submit"
                          id="continueButton"
                          onClick={() => {
                            handleSmartReserveSignatureUpdate('smart_reserve', smart_reserve_primary_sign);
                          }}
                          // disabled={!(smart_reserve_primary_sign)}
                          disabled={mainAccountsEntity.customerId === customerId ? !(smart_reserve_primary_sign) : !(smart_reserve_SecondarySign)}
                        >
                          Continue
                          &nbsp;<FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                      </div>
                    </Row>
                  </>
                )}
                {showDiv === 102 && (
                  <>
                    <FormDoc
                      docConfirmation3={docConfirmation3}
                      handleCheckboxChangeThree={handleCheckboxChangeThree}
                      handleUpdateNextStep={handleUpdateNextStep}
                      FormData2={FormData2}
                      email={email}
                      name={name}
                      accountHolderType={AccountType}
                      w8benPrimary_sign={w8benPrimary_sign}
                      setw8benPrimary_sign={setw8benPrimary_sign}
                      w8benSecondarySign={w8benSecondarySign}
                      setw8benSecondarySign={setw8benSecondarySign}
                      setEmail={setEmail}
                      setDifferentData={setDifferentData}
                      setMailing_address={setMailing_address}
                      mailing_address={mailing_address}
                      differentData={differentData}
                    />


                    <Row className="formDoc-button-arrangement">
                      <div className="col-4">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                          <FontAwesomeIcon icon={faArrowLeft} />&nbsp;
                          Previous
                        </Button>
                      </div>
                      <div className="col-4">
                        <Button className="button-account-custom" onClick={() => { saveFormdocWithServer('w8ben', w8benPrimary_sign) }}>
                          <FontAwesomeIcon icon={faSave} />&nbsp;
                          Save
                        </Button>
                      </div>
                      <div className='col-4'>
                        <Button
                          className="button-account-custom-submit"
                          id="continueButton"
                          onClick={() => {
                            handlew8benSignatureUpdate('w8ben', w8benPrimary_sign);
                          }}
                          disabled={!(w8benPrimary_sign || signatureData?.w8_ben?.primary_sign)}
                        // disabled={mainAccountsEntity.customerId === customerId ? !(w8benPrimary_sign) : !(w8benSecondarySign)}
                        >
                          Continue
                          &nbsp;<FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                      </div>
                    </Row>

                  </>
                )}
                {showDiv === 103 && (
                  <>
                    <W8benSecondary
                      docConfirmation3={docConfirmation3}
                      handleCheckboxChangeThree={handleCheckboxChangeThree}
                      handleUpdateNextStep={handleUpdateNextStep}
                      FormData2={FormData2}
                      email={email}
                      secondaryform={secondaryform}
                      setsecondaryform={setsecondaryform}
                      name={name}
                      accountHolderType={AccountType}
                      w8benPrimary_sign={w8benPrimary_sign}
                      setw8benPrimary_sign={setw8benPrimary_sign}
                      w8benSecondarySign={w8benSecondarySign}
                      setw8benSecondarySign={setw8benSecondarySign}
                      setEmail={setEmail}
                      setDifferentData={setDifferentData}
                      differentData={differentData}
                      mailing_address={mailing_address}
                      setMailing_address={setMailing_address}
                    />
                    <Row className="formDoc-button-arrangement">
                      <div className="col-6">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                          <FontAwesomeIcon icon={faArrowLeft} />&nbsp;
                          Previous
                        </Button>
                      </div>
                      <div className='col-6'>
                        <Button
                          className="button-account-custom-submit"
                          id="continueButton"
                          onClick={() => {
                            handlew8benSecondarySignatureUpdate('w8ben', w8benSecondarySign);
                            handleUpdateNextStep(showDiv);
                          }}
                          //disabled={!w8benPrimary_sign}
                          disabled={mainAccountsEntity.customerId === customerId ? !(w8benPrimary_sign) : !(w8benSecondarySign)}

                        >
                          Continue
                          &nbsp;<FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                      </div>
                    </Row>
                  </>
                )}
                {showDiv === 104 && (
                  <>
                    <InteractiveBrokers
                      interactive={interactive}
                      accountIdNum={accountIdNum}
                      accountHolderType={mainAccountsEntity?.accountHolderType || AccountType}
                      ibkrSecondarySign={ibkrSecondarySign}
                      setibkrSecondarySign={setibkrSecondarySign}
                      ibkrPrimary_sign={ibkrPrimary_sign}
                      setibkrPrimarySign={(data) => { setibkrPrimarySign(data) }}
                      handleCheckboxChange={handleCheckboxChange}
                    // handleCheckboxibustomerAgreement={handleCheckboxibustomerAgreement}
                    // handleCheckboxibclientFinancialAdvisor={handleCheckboxibclientFinancialAdvisor}
                    // handleCheckboxelt={handleCheckboxelt}
                    // handleCheckboxibsdNotification={handleCheckboxibsdNotification}
                    // handleCheckboxLLA={handleCheckboxLLA}
                    />

                    <Row className="formDoc-button-arrangement">
                      <div className=" col-4">
                        <Button className="button-account-custom" onClick={() => handlePreviousStep(showDiv)}>
                          <FontAwesomeIcon icon={faArrowLeft} />&nbsp;
                          Previous
                        </Button>
                      </div>
                      <div className="col-4">
                        <Button className="button-account-custom" onClick={() => {
                          saveIbkrWithServer('ibkr', ibkrPrimary_sign)
                          handleCheckboxChange;
                        }}>

                          <FontAwesomeIcon icon={faSave} />&nbsp;
                          Save
                        </Button>
                      </div>
                      <div className='col-4'>
                        <Button
                          className="button-account-custom-submit"
                          id="continueButton"

                          onClick={() => {
                            handleIBSignatureUpdate('ibkr', ibkrPrimary_sign);

                          }}
                          // disabled={!(interactive.elt && interactive.ib_client_financial_advisor && interactive.ib_customer_agreement
                          //   && interactive.ibsd_notification && interactive.lla && ibkrPrimary_sign === null
                          // )}

                          disabled={!(interactive?.elt &&
                            interactive?.ib_client_financial_advisor &&
                            interactive?.ib_customer_agreement &&
                            interactive?.ibsd_notification &&
                            interactive?.lla) ||
                            (mainAccountsEntity.customerId === customerId ? !(ibkrPrimary_sign) : !(ibkrSecondarySign))
                          }

                        >
                          Submit
                        </Button>
                      </div>
                    </Row>
                  </>
                )}
              </div>
            </Form>
          </div>
        </Col >
      </Row >

      <Modal show={!checkboxStates.StarlightFieldConfirmation && showModal} onHide={handleCloseModal}>
        <ModalHeader closeButton>
          <span>Reminder</span>
        </ModalHeader>
        <ModalBody>Please confirm the checkbox before proceeding.</ModalBody>
        <ModalFooter>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>


      <CommonToast
        visible={open6}
        onClose={toggle6}
        // title={'Test'}
        needCloseButton={true}
        type="custom"
        isAutoClose={false}
      >
        <div className="custom-content">

          <div className="font-weight-bold custom-modal-style text-justify-custom" style={{ margin: '0' }}>
            Thank you for providing the details of the Secondary Applicant for your Joint Starlight Account. As this is a joint application, the Secondary Applicant needs to sign the required agreements. Once you have completed the application, the Secondary Applicant will automatically receive an email at <span className='email-modal-style'>{mainAccountsEntity?.joint_details?.joint_username}</span> with instructions on how to sign the agreements.
          </div>
          <br />
          {/* <Button color="primary" onClick={toggle6}>Continue</Button> */}
          <div className="row justify-content-center">
            <div className='col-6'>
              <Button
                className="button-account-custom-submit"
                onClick={toggle6}
              >
                Continue
              </Button>

            </div>
          </div>
        </div>
      </CommonToast>

      <CommonToast
        visible={open5}
        onClose={toggle5}
        // title={'Test'}
        needCloseButton={true}
        type="custom"
        isAutoClose={false}
      >
        {updateSuccess && (

          <Row className="mt-5 align-items-center">
            <Col className="d-flex justify-content-center" xs={12} md={12}>
              <img className="img-fluid logo-dashboard" src="../../../../../content/images/new_logo_ash_bg_color.svg" />
            </Col>
            <Col className="col-12 font-weight-bold custom-modal-style text-justify-custom" >
              <div style={{ fontWeight: 600 }}>Your Starlight Smart Account <br />application is currently under review </div><br /><br />
              <div className='text-start'>
                Your application has been saved. Your application will be submitted once the Secondary Applicant has signed the agreements. We will update you shortly. <br />You can also check your account status in your&nbsp;
                <Link to="/dashboard" className="" style={{ color: '#597177' }}>
                  Client Portal
                </Link>.<br /><br />
              </div>
              <div className='text-start'>
                We look forward to helping you create a brighter future for you and your family
              </div>
              <br />
              <div className="row justify-content-center pt-3">
                <div className='col-6'>
                  <a rel="noreferrer" href="https://www.starlightwealth.ie" style={{ color: '#eee' }}>
                    <Button
                      className="button-account-custom-submit"
                      onClick={toggle5}
                    >
                      Close
                    </Button>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </CommonToast>

      <CommonToast
        visible={open}
        onClose={toggle}
        // title={'Test'}
        needCloseButton={true}
        type="custom"
        isAutoClose={false}
        closeBtn={true}
      >
        {updateSuccess && (
          <Row className='justify-content-center '>
            <Col className='col-12'>
              <div className="font-weight-bold custom-modal-style text-justify-custom" style={{ margin: '0' }}>
              <img 
            className="img-fluid logo-dashboard" 
            style={{ display: 'block', margin: '0 auto' }} 
            src="../../../../../content/images/new_logo_white_bg_color.svg" 
          />
                <div className='text-center pt-3'>
                  <span style={{ fontWeight: 600 }}>Your Starlight Smart Account 
                  <br />application is currently under review</span><br /><br />
                </div>
                <div className='text-center'>
                  We will be in touch by email if we need anything else
                  and will let you know when your account has been approved.
                  <br /><br />
                </div>
                <div className='text-center'>
                  Thank you for your patience!
                  <br /><br />
                  <div className='text-center'>The Starlight Wealth Team</div>
                   
                </div>
                <div className="row justify-content-center pt-2">
                  <div className='col-6'>
                    {/* <a rel="noreferrer" href="https://www.starlightwealth.ie" style={{ color: '#eee' }}> */}
                      <Button
                        className="button-account-custom-submit"
                        onClick={toggle}
                      >
                        Close
                      </Button>
                    {/* </a> */}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </CommonToast>


      <CommonToast
        visible={open7}
        title={"Once we have your Autograph, you're all set!"}
        needCloseButton={true}
        type="custom"
        isAutoClose={false}
        onClose={handleContinue}
      >
        <div className="font-weight-bold custom-modal-style-big text-justify-custom" >
          There are a few documents which we need you to sign before we can open your account.
        </div>
        <br />
        {/* <Button color="primary" onClick={handleContinue}>Continue</Button> */}
        <div className='row justify-content-center '   >
          <div className='col-6'>
            <Button
              className="button-account-custom-submit"
              onClick={handleContinue}
            >
              Continue
            </Button>
          </div>
        </div>
      </CommonToast>


      <CommonToast
        visible={open2}
        title={'Your Statement of Suitability'}
        needCloseButton={true}
        type="custom"
        isAutoClose={false}
        onClose={toggle2}
      >
        <div className="font-weight-bold  custom-modal-style text-justify-custom">
          <div className='' style={{ fontWeight: 600, textAlign: 'left', marginBottom: '10px' }}> </div>
          A Statement of Suitability is required by Irish regulation and sets out the reasons why the products or services offered or recommended by us are considered suitable for your particular needs, objectives, and circumstances. Please review the document, scroll down to the bottom and sign by typing your full name in the box provided to confirm your agreement.
        </div>
        <br />
        {/* <Button color="primary" onClick={toggle2}>
          Continue
        </Button> */}
        <div className="row justify-content-center">
          <div className='col-6'>
            <Button
              className="button-account-custom-submit"
              onClick={toggle2}
            >
              Continue
            </Button>

          </div>
        </div>
      </CommonToast>


      <CommonToast
        visible={open3}
        // onClose={toggle7}
        title={'Starlight Smart Reserve Model Portfolio Description'}
        needCloseButton={true}
        type="custom"
        isAutoClose={false}
        onClose={toggle3}
      >
        <div className="font-weight-bold custom-modal-style text-justify-custom" >

          This document provides you with more information on how your funds will be invested.
          Please review the document, scroll down to the bottom and sign by typing your full name in the box provided to confirm your agreement.
        </div>
        <br />
        {/* <Button color="primary" onClick={toggle3}>
          Continue
        </Button> */}
        <div className="row justify-content-center">
          <div className='col-6'>
            <Button
              className="button-account-custom-submit"
              onClick={toggle3}
            >
              Continue
            </Button>

          </div>
        </div>
      </CommonToast>



      <CommonToast
        visible={open4}
        title={'W-8BEN Form'}
        needCloseButton={true}
        type="custom"
        isAutoClose={false}
        onClose={toggle4}
      >
        <div className="font-weight-bold custom-modal-style text-justify-custom" >
          <div className='' style={{ fontWeight: 600 }}> </div>

          A W-8BEN Form is a US Tax form. It enables Irish residents to receive preferential tax treatment in certain circumstances. You are required to complete this form to open a <span className='' style={{ color: 'black', fontWeight: 'bold' }}>Starlight Smart Account</span>.
          Please review the document, scroll down to the bottom and sign by typing your full name in the box provided to confirm your agreement. For more information on W-8BEN Forms please click
          &nbsp;
          <a href="../../../content/policies/2025 Starlight Wealth W8-BEN 101 v1.1.2025.pdf" target="_blank" style={{ color: '#597177', }} rel="noreferrer">
            here
          </a>

        </div>
        <br />
        {/* <Button color="primary" onClick={toggle4}>
          Continue
        </Button> */}
        <div className="row justify-content-center">
          <div className='col-6'>
            <Button
              className="button-account-custom-submit"
              onClick={toggle4}
            >
              Continue
            </Button>

          </div>
        </div>
      </CommonToast>

      <CommonToast
        visible={saveToast}
        // title={'Succes'}
        needCloseButton={true}
        type="custom"
        isAutoClose={false}
        onClose={togglesave}
      >
        <div className="font-weight-bold custom-modal-style text-justify-custom" >
          <div className='modal-text text-center'>
          Your information has been saved successfully!
          </div>
          <div className='modal-text pt-2 text-center'>
          If you need to come back to us, don’t worry,
          when you login again you can pick up where you have left off.
          </div>
          <div className='modal-text pt-3 text-center'>
            The Starlight Wealth Team
          </div>
        </div>
        <div className="row pt-4">
        <div className='col-6'>
            <Button
              className="button-account-custom-submit"
              onClick={togglelogout}
            >
              Logout
            </Button>

          </div>
          <div className='col-6'>
            <Button
              className="button-account-custom-submit"
              onClick={togglesave}
            >
              Continue
            </Button>

          </div>
        </div>
      </CommonToast>

    </div >
  );
};

export default Accounts;
