import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { forwardRef, useImperativeHandle } from 'react';
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { updateEntity, updateEntityProperty } from './accounts.reducer';
import { Helmet } from "react-helmet";

interface PepProps {
  onFormComplete: (completed: boolean) => void;
}

export interface PepRef {
  syncPepWithServer: () => Promise<any>;
  savePepWithServer: () => Promise<any>;
}

const Pep: React.ForwardRefRenderFunction<PepRef, PepProps> = ({ onFormComplete }: PepProps, ref) => {
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const dispatch = useAppDispatch();

  useImperativeHandle(ref, () => ({
    syncPepWithServer,
    savePepWithServer
  }));

  const syncPepWithServer = (): Promise<any> => {
    return dispatch(updateEntity({ ...mainAccountsEntity, last_level: 8 }));
  };

  const savePepWithServer = (): Promise<any> => {
    if (mainAccountsEntity?.accountId && mainAccountsEntity.accountId !== '') {

      return dispatch(updateEntity({ ...mainAccountsEntity }));
    }
  }

  const handleAreYouPepChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(updateEntityProperty({
      isPep: e.target.checked
    }));
  };

  const handleFamilyMemberPepChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(updateEntityProperty({
      isFamilyPep: e.target.checked
    }));
  };

  const handleFamilyMemberPepDetailsChange = e => {
    const { value } = e.target;
    dispatch(updateEntityProperty({ familymemberpepdetails: value }));
  };

  // Checking if both checkboxes are checked
  const isBothChecked = mainAccountsEntity?.isPep === true && mainAccountsEntity?.isFamilyPep === true;

  return (
    <div>
      <Row>
        <Col md="12" className="form-heading-custom pt-3">
          Politically Exposed Person (PEP)
        </Col>
        <Col md="12" className='pt-4 pb-3'>
          <Card className='form-custom-card'>
            <CardBody>
              <Col md="12" className="form-sub-content-custom">
                We are required by law to identify if any of our clients are PEPs and, if so, to apply certain checks and controls before opening
                an account for them or providing any services.
              </Col>
              <Col md="12" className="pt-3 form-sub-content-custom">
                A “PEP” is defined as a person who is or has been entrusted with a prominent public function in Ireland or abroad, including:
                politicians, senior executives of government bodies, or immediate family members of the above.
              </Col>
            </CardBody>
          </Card>
        </Col>

        <Col md="12 pt-3">
          <FormGroup>
            <Label check className="label-custom-form-check-box">
              <Input
                type="checkbox"
                name="ARE_YOU_A_PEP"
                id="ARE_YOU_A_PEP"
                className="input-account-custom-bgnone me-1 pt-1"
                checked={mainAccountsEntity?.isPep === true}
                onChange={handleAreYouPepChange}
              />{' '}
              I confirm that I am not and have never been entrusted with a prominent public function in Ireland or abroad, whether as a politician or as a senior executive of a government body.
            </Label>
          </FormGroup>
        </Col>

        <Col md="12">
          <FormGroup>
            <Label check className="label-custom-form-check-box">
              <Input
                type="checkbox"
                name="FAMILY_MEMBER_PEP"
                id="FAMILY_MEMBER_PEP"
                className="input-account-custom-bgnone me-1 pt-1"
                checked={mainAccountsEntity?.isFamilyPep === true}
                onChange={handleFamilyMemberPepChange}
              />{' '}
              I confirm that no immediate family member has ever been entrusted with a prominent public function in Ireland or abroad, whether as a politician or as a senior executive of a government body.
            </Label>
          </FormGroup>
        </Col>

        {/* Conditional rendering of the details section */}
        {!isBothChecked && (
          <Col md="12">
            <FormGroup>
              <Label className="label-account-class">
                * Please Provide Details

                <br />
                <span className="account-sub-span-custom">
                  {`If you are unable to provide both statements of confirmation please provide a brief explanation here:`}
                </span>
              </Label>
              <Input
                type="text"
                name="familymemberpepdetails"
                id="familymemberpepdetails"
                value={mainAccountsEntity?.familymemberpepdetails}
                className="custom-from-input"
                onChange={handleFamilyMemberPepDetailsChange}
              />
            </FormGroup>
          </Col>
        )}
          <Helmet> 
          <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/145768795.js" ></script>
          </Helmet>
      </Row>
    </div>
  );
};

export default forwardRef(Pep);
