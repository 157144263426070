import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Row, Col, FormGroup, Label, Input, Form } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { uploadDocument, updateEntity, updateEntityProperty, getCountryList, getState } from '../accounts/accounts.reducer';
import { useForm } from 'react-hook-form';
import { getCustomerDetails } from '../customer/customer.reducer';
import { getPendingTask } from 'app/modules/profile/profile.reducer';
import CommonToast from 'app/modules/components/CommonToast';
import { Helmet } from "react-helmet";

interface primaryAddressDetailsProp {
  onFormComplete: (completed: boolean) => void;
}

export interface primaryAddressDetailsRef {
  syncPrimaryAddressDetailsWithServer: () => Promise<any>;
  savePrimaryAddressDetailsWithServer: () => Promise<any>;
}
 const PrimaryAddressDetails : React.ForwardRefRenderFunction<primaryAddressDetailsRef, primaryAddressDetailsProp> = ({ onFormComplete }: primaryAddressDetailsProp, ref) => {
  const dispatch = useAppDispatch();

  const customerDetails = useAppSelector(state => state.customer.entities);
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const [state_or_province, setState_or_province] = useState<string>(mainAccountsEntity?.state_or_province ||'');
  const [country, setCountry] = useState<any>("Ireland");
  const [issued_country, setIssued_country] = useState<any>(mainAccountsEntity?.countryList?.find((data) => data.name === 'Ireland') || {});
  const [passport_expiry_date, setPassport_expiry_date] = useState<string>(mainAccountsEntity?.passport_expiry_date || '');
   const [employment_status, setEmployment_status] = useState<string>(mainAccountsEntity?.employment_status || 'EMPLOYED');
    const [occupation, setOccupation] = useState<string>(mainAccountsEntity?.occupation || '');
    const [employerName, setEmployerName] = useState<string>(mainAccountsEntity?.employerName || '');
    const [employerBusiness, setEmployerBusiness] = useState<string>(mainAccountsEntity?.employerBusiness || '');
    const [isapubliclylistedcompany, setIsapubliclylistedcompany] = useState<string>(false || mainAccountsEntity?.isapubliclylistedcompany);
    const [employerCompany, setEmployerCompany] = useState<string>(mainAccountsEntity?.employerCompany || '');
  type PassportExpiryErrorType = {
    passport_expiry_date?: string;
  };
  const [othertaxresidence, setOthertaxresidence] = useState<string>(mainAccountsEntity?.othertaxresidence || '');
  const [stateList, setStateList] = useState<string[]>([]);
  const [countryList, setCountryList] = useState<string[]>(mainAccountsEntity?.countryList || []);

  interface IValidationErrors {
    [key: string]: string;
  }

  useImperativeHandle(ref, () => ({
    syncPrimaryAddressDetailsWithServer,
    savePrimaryAddressDetailsWithServer
  }));

  useEffect(() => {
    dispatch(getState()).then((res) => {
      if (res.payload) {
        const stateData = res.payload.map((item: any) => item);
        setStateList(stateData);
      }
    });

  }, [dispatch]);

  const accessToken = localStorage.getItem('jhi-authenticationToken');
  useEffect(() => {

    if (accessToken) {
      dispatch(getCountryList()).then((res) => {
        dispatch(getCustomerDetails());
        if (res.payload) {
          const countryData = res.payload.map((item: any) => item);
          setCountryList(countryData);
        }
      });
    }

  }, [dispatch]);

  useEffect(() => {
    if (customerDetails) {
  
      const updatedProperties = {
        state_or_province:(state_or_province === '' || state_or_province== null)
           ? mainAccountsEntity?.state_or_province: state_or_province,  
        country:(country === '' || country== null)
            ? (mainAccountsEntity?.country|| 'Ireland'): country,
        issued_country: mainAccountsEntity?.issued_country ? mainAccountsEntity.issued_country : 'Ireland',
        employment_status: customerDetails?.employmentStatus ||mainAccountsEntity.employment_status || 'EMPLOYED',
       
        birth_info: {
          countryOfBirth: mainAccountsEntity?.birth_info?.countryOfBirth || 'Ireland',
        },
        taxResidentInIreland:     mainAccountsEntity?.taxResidentInIreland ?? true,
  
        passport_expiry_date: (passport_expiry_date === '' || passport_expiry_date== null)
        ? mainAccountsEntity?.passport_expiry_date: passport_expiry_date,
        employerAddress: {
            ...mainAccountsEntity?.employerAddress,
            country: (employerAddress?.country === '' || employerAddress?.country== null)
            ? (mainAccountsEntity?.employerAddress?.country||'Ireland'):employerAddress?.country,
            state: (employerAddress?.state === '' || employerAddress?.state== null)
            ? (mainAccountsEntity?.employerAddress?.state||''):employerAddress?.state, 
          },
      };

      dispatch(updateEntityProperty(updatedProperties));
    }
  }, [customerDetails]);

  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({});
  const [passportexpiryError, setPassportExpiryError] = useState<PassportExpiryErrorType>({});

  const [isUploadingOne, setIsUploadingOne] = useState(false);
  const [isUploadingTwo, setIsUploadingTwo] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<string>(mainAccountsEntity?.ppsNumberDoc || '');
  const [uploadedPassport, setUploadedPassport] = useState<string>(mainAccountsEntity?.passportDoc || '');
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const [ppsError, setPpsError] = useState<string | null>(null);
  const [pendingTaskAccountId, setPendingTaskAccountId] = useState([])
  const accountsEntity = sessionStorage?.getItem('accountid');

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    getFieldState,
    formState: { isValid,touchedFields },
  } = useForm({ mode: 'onChange' });

  const findCountryNameByKey = (key: string | null | undefined): string => {
    const countryName: any = countryList.find((obj: any) => obj.name === key);
    return countryName ? countryName.name : '';
  };



  const syncPrimaryAddressDetailsWithServer = (): Promise<any> => {

    const _errors: IValidationErrors = {};

    
    // if (!addressLine1) {
    //   _errors.addressLine1 = 'This field is mandatory';
    // }
    // if (!townOrCity) {
    //   _errors.townOrCity = 'This field is mandatory';
    // }
    // if (!postcode) {
    //   _errors.postcode = 'This field is mandatory';
    // }
    // if (!ppsNumber) {
    //   _errors.ppsNumber = 'This field is mandatory';
    // }
    // if (!uploadedFile) {
    //   _errors.uploadedFile = 'This field is mandatory';
    // }
    // if (!passport_number) {
    //   _errors.passport_number = 'This field is mandatory';
    // }
    // if (!uploadedPassport) {
    //   _errors.uploadedPassport = 'This field is mandatory';
    // }
    // if (!citizenship) {
    //   _errors.citizenship = 'This field is mandatory';
    // }
    // if (!state_or_province){
    //   _errors.state_or_province = 'This field is mandatory';
    // }
    // if (!passport_expiry_date) {
    //   _errors.passport_expiry_date = 'This field is mandatory';
    // }
    // if (!passport_expiry_date) {
    //   _errors.passport_expiry_date = 'This field is mandatory';
    // } else if (new Date(passport_expiry_date) < new Date(minAllowedDate)) {
    //   _errors.passport_expiry_date = '';
    // }
    // if (istaxresident === false && !othertaxresidence) {
    //   _errors.othertaxresidence = 'This field is mandatory';
    // }
    if (employment_status === "EMPLOYED") {
      if (!occupation) {
        _errors.occupation = 'This field is mandatory';
      }
      if (!employerName) {
        _errors.employerName = 'This field is mandatory';
      }
      if (!employerBusiness) {
        _errors.employerBusiness = 'This field is mandatory';
      }
      if (!employerAddress?.city) {
        _errors.city = 'This field is mandatory';
      }
      if (!employerAddress?.state){
        _errors.state ='This field is mandatory';
      }
      if (!employerAddress?.postal_code) {
        _errors.postal_code = 'This field is mandatory';
      }
      if (!employerAddress?.street) {
        _errors.street = 'This field is mandatory';
      }
    }
   
    if (Object.keys(_errors).length > 0) {
      setValidationErrors(_errors);
      setToastMessage((prev) => 'Please fill all the mandatory fields');
      setToastType('error');
      setToastVisible(true);
      return null;
    }
    setValidationErrors({});
    return dispatch(updateEntity({ ...mainAccountsEntity, last_level: 4 }));
  };

  const savePrimaryAddressDetailsWithServer = (): Promise<any> => {
    if (mainAccountsEntity?.accountId && mainAccountsEntity.accountId !== '') {

      return dispatch(updateEntity({ ...mainAccountsEntity }));
    }
    }

  const clearValidationError = (field: string) => {
    setValidationErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
    setPassportExpiryError(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };
   const [employerAddress, setEmployerAddress] = useState({
      street: mainAccountsEntity?.employerAddress?.street,
      city: mainAccountsEntity?.employerAddress?.city,
      state: mainAccountsEntity?.employerAddress?.state  ,
      country: mainAccountsEntity?.employerAddress?.country || 'Ireland',
      postal_code: mainAccountsEntity?.employerAddress?.postal_code
  
    });

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    const { name, value, id } = e.target;
    const lcl_errors: { [key: string]: string } = {};

     if (name === 'state_or_province') {
      setState_or_province(value);
    } else if (name === 'country') {
      setCountry(value);
    } else if (name === 'issued_country') {
      setIssued_country(value);
    } else if (name === 'othertaxresidence') {
      setOthertaxresidence(value);
    }
    else if (name === 'occupation') {
      setOccupation(value);
    } else if (name === 'employment_status') {
      setEmployment_status(value);
    } else if (name === 'employerName') {
      setEmployerName(value);
    } else if (name === 'employerBusiness') {
      setEmployerBusiness(value);
    }
    else if (name === 'isapubliclylistedcompany') {
      setIsapubliclylistedcompany(value);
    }
    else if (name === 'employerCompany') {
      setEmployerCompany(value);
    }
    if (value) {
      clearValidationError(name);
    }
    dispatch(updateEntityProperty({
      [name]: value
    }));

  };

  
  useEffect(() => {
    dispatch(getPendingTask({}))
      .then((response: any) => {
        setPendingTaskAccountId(response?.payload?.data?.pending_accounts)
      })
  }, [])

 
   const handleCountryIssued = (e: any) => {
      const { name, value } = e.target;
      setEmployerAddress(prevEmployerAddress => ({
        ...prevEmployerAddress,
        [name]: value
      }));
  
      if (name === 'postal_code') {
  
        dispatch(updateEntityProperty({ employerAddress: { ...mainAccountsEntity.employerAddress, ['postal_code']: value } }));
      } else {
        dispatch(updateEntityProperty({ employerAddress: { ...mainAccountsEntity.employerAddress, [name]: value } }));
      }
      if (value) {
        clearValidationError(name);
      }
  
    }
  
  


  return (
    <div>
    <CommonToast
    visible={toastVisible}
    onClose={() => setToastVisible(false)}
    title={toastType === 'success' ? 'Success' : 'Error'}
    description={toastMessage}
    type={toastType}
    isAutoClose={true}
  />


        <Row>
           <Col md="12" className="form-heading-custom pt-3 pb-3">
                     Primary Applicant Employment Details
                      </Col>
        <Col md="12" className="from-sub-heading-custom">Employment Details</Col>
          <Col md="12" className="pt-3">
              <FormGroup>
                <Label className="label-custom-form-input"><span>*</span> Employment Status</Label>
                <Input
                  type="select"
                  name="employment_status"
                  id="employment_status"
                  className="custom-from-input"
                  value={employment_status}
                  defaultValue={mainAccountsEntity?.employment_status}
                  onChange={handleValueChange}
                >

                  <option value={"EMPLOYED"}>Employed</option>
                  <option value={"SELFEMPLOYED"}>Self Employed</option>
                  <option value={"UNEMPLOYED"}>Unemployed</option>
                  <option value={"RETIRED"}>Retired</option>
                  <option value={"STUDENT"}>Student</option>
                  <option value={"HOMEMAKER"}>Home Maker</option>

                </Input>
              </FormGroup>
            </Col>
            {employment_status === 'EMPLOYED' || employment_status === 'SELFEMPLOYED' ? (
              <>
                <Col md="12">
                  <FormGroup>
                    <Label className="label-custom-form-input"><span>*</span> Occupation</Label>
                    <Input
                      type="text"
                      name="occupation"
                      id="occupation"
                      value={occupation}
                      defaultValue={mainAccountsEntity?.occupation}
                      className="custom-from-input"
                      onChange={e => handleValueChange(e)}
                    />
                    {validationErrors.occupation && <div className="text-danger">{validationErrors.occupation}</div>}{' '}

                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label className="label-custom-form-input"><span>*</span> Employer Name</Label>
                    <Input
                      type="text"
                      name="employerName"
                      id="employerName"
                      value={employerName}
                      defaultValue={mainAccountsEntity?.employerName}
                      className="custom-from-input"
                      onChange={e => handleValueChange(e)}
                    />
                    {validationErrors.employerName && <div className="text-danger">{validationErrors.employerName}</div>}{' '}

                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label className="label-custom-form-input"><span>*</span> Employer Business</Label>
                    <Input
                      type="text"
                      name="employerBusiness"
                      value={employerBusiness}
                      defaultValue={mainAccountsEntity?.employerBusiness}
                      id="employerBusiness"
                      className="custom-from-input"
                      onChange={e => handleValueChange(e)}
                    />
                    {validationErrors.employerBusiness && <div className="text-danger">{validationErrors.employerBusiness}</div>}{' '}

                  </FormGroup>
                </Col>
                <Col md="12" className="pt-2">
                  <FormGroup>
                    <Label className="from-sub-heading-custom">Employer Address</Label>
                  </FormGroup>
                </Col>
                <Col md="12" className="">
                  <FormGroup>
                    <Label className="label-custom-form-input"><span>*</span> Street</Label>
                    <Input
                      type="text"
                      name="street"
                      // value={employerAddressStreet}
                      defaultValue={employerAddress?.street}
                      id="street"
                      className="custom-from-input"
                      onChange={e => handleCountryIssued(e)}
                    />
                    {validationErrors.street && <div className="text-danger">{validationErrors.street}</div>}{' '}

                  </FormGroup>
                </Col>

                <Col md="12" className="pt-2">
                  <FormGroup>
                    <Label className="label-custom-form-input"><span>*</span> City</Label>
                    <Input
                      type="text"
                      name="city"
                      // value={employerAddressCity}
                      defaultValue={employerAddress?.city}
                      id="city"
                      className="custom-from-input"
                      onChange={e => handleCountryIssued(e)}
                    />
                    {validationErrors.city && <div className="text-danger">{validationErrors.city}</div>}{' '}

                  </FormGroup>
                </Col>
                <Col md="12" className="pt-2">
                  <FormGroup>
                    <Label className="label-custom-form-input"><span>*</span> County</Label>
                    <Input
                      type="select"
                      name="state"
                      defaultValue={mainAccountsEntity?.employerAddress?.state}
                      id="state"
                      value={employerAddress.state}
                      className="custom-from-input"
                      onChange={e => handleCountryIssued(e)}
                    >
                      {/* Render the mainAccountsEntity.state_or_province as an option if it exists */}
                      {/* {mainAccountsEntity?.state_or_province && ( */}
                        <option value="">
                          {/* {mainAccountsEntity.state_or_province} */}
                        </option>
                      {/* )} */}
                      {/* Map through stateList and render options */}
                      {stateList && stateList.map((item: any, index) => (
                        <option key={index} value={item.name}>{item.name}</option>
                      ))}
                    </Input>

                    {validationErrors.state && <div className="text-danger">{validationErrors.state}</div>}{' '}

                  </FormGroup>
                </Col>
                <Col md="12" className="pt-2">
                  <FormGroup>
                    <Label className="label-custom-form-input"><span>*</span> Country</Label>
                    <Input
                      type="select"
                      name="country"
                      defaultValue={employerAddress?.country || "Ireland"}
                      id="country"
                      className="custom-from-input"
                      onChange={e => handleCountryIssued(e)}
                    >
                      <>
                        <> <option value={country.name}>{issued_country.name || findCountryNameByKey("Ireland")}</option></>
                        {countryList && countryList?.filter((obj: any) => obj.name !== 'Ireland')?.map((item: any, index) => (
                          <option key={index} value={item.name}>{item.name}</option>
                        ))}
                      </>
                    </Input>

                    {validationErrors.employerAddressCountry && <div className="text-danger">{validationErrors.employerAddressCountry}</div>}{' '}

                  </FormGroup>
                </Col>
                <Col md="12" className="pt-2">
                  <FormGroup>
                    <Label className="label-custom-form-input"><span>*</span> Post Code</Label>
                    <Input
                      type="text"
                      name="postal_code"
                      // value={employerAddressPostal_code}
                      defaultValue={employerAddress?.postal_code}
                      id="postal_code"
                      className="custom-from-input"
                      onChange={e => {
                        const uppercaseValue = e.target.value.toUpperCase(); 
                        const name = e.target.name;
                        handleCountryIssued({ ...e, target: { ...e.target, value: uppercaseValue, name } }); 
                      }}
                      style={{ textTransform: 'uppercase' }}
                    />
                    {validationErrors.postal_code && <div className="text-danger">{validationErrors.postal_code}</div>}{' '}

                  </FormGroup>
                </Col>

                <Col md="12" className="pt-2">
                  <FormGroup>
                    <Label className="label-custom-form-input">
                      <span>*</span>
                      <span> Is the Employer a Publicly Listed Company?</span>{' '}
                    </Label>
                    <Input
                      type="select"
                      name="isapubliclylistedcompany"
                      value={isapubliclylistedcompany}
                      defaultValue={mainAccountsEntity?.isapubliclylistedcompany}
                      id="isapubliclylistedcompany"
                      className="custom-from-input"
                      onChange={e => handleValueChange(e)}
                    >
                      {validationErrors.isapubliclylistedcompany && <div className="text-danger">{validationErrors.isapubliclylistedcompany}</div>}{' '}

                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </Input>
                  </FormGroup>
                  {mainAccountsEntity?.isapubliclylistedcompany === "true" || mainAccountsEntity?.employerCompany ? (
                    <FormGroup>
                      <Label className="label-custom-form-input">
                        <span>*</span>
                        <span> Employer Company Stock Ticker Symbol</span>{' '}
                      </Label>
                      <Input
                        type="text"
                        name="employerCompany"
                        id="employerCompany"
                        value={employerCompany}
                        defaultValue={mainAccountsEntity?.employerCompany}
                        className="custom-from-input"
                        onChange={e => handleValueChange(e)}
                      />
                    </FormGroup>
                  ) : null}
                </Col>
              </>
            ) : null}

            <Helmet> 
              <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/145768795.js" ></script>
             </Helmet>
             
             {/* <Helmet>
            <script
              type="text/javascript"
              id="hs-script-loader"
              async defer src={`//js.hs-scripts.com/49098608.js`}
              >
            </script>
          </Helmet> */}
          </Row>
    </div>
  );
};

export default forwardRef(PrimaryAddressDetails);
